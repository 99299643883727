import { getErrorMessage } from "@design-system/components/helpers/finalForm";
import { StyledInput } from "@design-system/components/RhTextField/RhTextField.styled";
import { InputProps as IInputProps } from "@mui/material/Input/Input";
import { Property } from "csstype";
import { FieldValidator } from "final-form";
import { identity } from "lodash";
import React, { FC, FocusEvent } from "react";
import { FieldMetaState, useField } from "react-final-form";

export interface InputStyles {
  paddingRight?: Property.PaddingRight;
  textAlign?: Property.TextAlign;
}

const getHasErrors = (meta: FieldMetaState<string>) => {
  const hasSyncError = Boolean(meta.error);
  const hasAsyncError = Boolean(meta.submitError);
  const hasValidationError =
    hasSyncError || (!meta.dirtySinceLastSubmit && hasAsyncError);

  return !meta.active && meta.touched && hasValidationError;
};

interface RhTextFieldProps<Type> {
  InputProps?: IInputProps;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  format?: (value: Type, name: string) => string;
  inputMode?: "text" | "numeric" | "email";
  keepEmptyStrings?: boolean;
  name: string;
  parse?: (value: Type, name: string) => string;
  placeholder?: string;
  readOnly?: boolean;
  styles?: InputStyles;
  type?: "text" | "email" | "date" | "tel" | "password" | "number";

  validate?: FieldValidator<Type>;
}

// See our notes on using Final Form here:
// https://www.notion.so/gotrhythm/Forms-2247211da478413e9552b38f150484b1
/**
 *
 * @param props.name
 * @param props.placeholder
 * @param props.type
 * @param props.disabled
 * @param props.readOnly
 * @param props.keepEmptyStrings
 * By default, RFF will parse empty strings into undefined when storing in the form state, and formats undefined to empty strings when reading from the form state. Set this to true to keep empty strings in the form state. Ignored by `props.parse` and `props.format` if those are defined.
 * @param props.autoComplete
 * @param props.inputMode
 * @param props.InputProps
 * @param props.format
 * @param props.parse
 * @param props.validate
 * @param props.styles
 * @param props.children
 * @returns
 */
export const RhTextField: FC<
  React.PropsWithChildren<RhTextFieldProps<string>>
> = ({
  autoComplete = "off",
  autoFocus,
  format,
  parse,
  keepEmptyStrings = false,
  name,
  placeholder,
  type,
  validate,
  disabled,
  styles,
  readOnly,
  inputMode = "text",
  InputProps,
  children,
}) => {
  const parseFn = parse || (keepEmptyStrings ? identity : undefined);
  const formatFn = format || (keepEmptyStrings ? identity : undefined);

  const {
    input: { onBlur: rffOnBlur, ...input },
    meta,
  } = useField<string>(name, {
    format: formatFn,
    parse: parseFn,
    type,
    validate,
  });

  const hasErrors = getHasErrors(meta);
  const errorMessage = hasErrors ? getErrorMessage(meta) : undefined;
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    rffOnBlur(event);
    if (InputProps?.onBlur) {
      InputProps.onBlur(event);
    }
  };

  return (
    <StyledInput
      required
      {...input}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      placeholder={placeholder || ""}
      disabled={disabled || meta.submitting}
      error={hasErrors}
      readOnly={readOnly}
      inputProps={{
        inputMode,
        style: {
          ...styles,
        },
      }}
      errorMessage={errorMessage}
      {...InputProps}
      onBlur={handleBlur}
    >
      {children}
    </StyledInput>
  );
};
