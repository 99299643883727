import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const DefinitionList = styled(BoCard)<{ $columns?: number }>`
  --columns: ${({ $columns }) => $columns || 4};
  align-self: center;
  display: grid;
  gap: ${rhOpsSpacingPx(2)};
  grid-template-columns: repeat(var(--columns), 1fr);
  margin-bottom: ${rhOpsSpacingPx(3)};
  max-width: 1200px;
  padding: ${rhOpsSpacingPx(3)};
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
  }
`;
