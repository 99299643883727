import { capitalizeFirstLetterOfWords } from "@common/utils/stringFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoLinkButton } from "@ops-design-system/components/BoLinkButton/BoLinkButton";
import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import { Body1, H1 } from "@ops-design-system/components/Typography/Typography";
import { useCampaignsQuery } from "@pricing-data/hooks/queries/useCampaigns.query";
import { CampaignType } from "@pricing-utils/types/campaignTypes";
import { AddCampaign } from "@pricing/components/AddCampaign/AddCampaign";
import { CampaignSearchForm } from "@pricing/components/CampaignSearchForm/CampaignSearchForm";
import { campaignSearchFiltersAtom } from "@pricing/components/CampaignSearchForm/CampaignSearchForm.atoms";
import {
  ButtonContainer,
  Greeting,
  HeaderContainer,
  StyledGrid,
} from "@pricing/pages/CampaignsPage/CampaignsPage.styled";
import { campaignOffersPath } from "@pricing/routes/routePaths";
import { createColumnHelper } from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import React, { PropsWithChildren, useEffect, useState } from "react";

const CampaignsPageLayout = ({ children }: PropsWithChildren<unknown>) => {
  const [userInfo, setUserInfo] = useState<UserClaims>({} as UserClaims);

  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    oktaAuth.getUser().then((info) => {
      setUserInfo(info);
    });
  }, [oktaAuth]);

  return (
    <>
      <StyledGrid>
        <HeaderContainer>
          <H1>Campaign Management </H1>
          <ButtonContainer>
            <AddCampaign />
          </ButtonContainer>
        </HeaderContainer>

        <Greeting>{`Welcome, ${userInfo.given_name}`}</Greeting>
      </StyledGrid>
      {children}
    </>
  );
};

const columnHelper = createColumnHelper<CampaignType>();

const columns = [
  columnHelper.accessor("id", {
    header: "Campaign ID",
  }),
  columnHelper.accessor("campaignSlug", {
    header: "Campaign Slug",
    meta: {
      wrapContent: true,
    },
  }),
  columnHelper.accessor("utility.name", {
    header: "Utility",
    meta: {
      wrapContent: true,
    },
  }),
  columnHelper.accessor("activeOffersCount", {
    header: "# of Offers",
    meta: {
      fitContent: true,
    },
  }),
  columnHelper.display({
    cell: ({ row }) => row.original.promo?.code,
    header: "Promo",
  }),
  columnHelper.accessor("status", { header: "Status" }),
  columnHelper.accessor("type", {
    cell: (props) => capitalizeFirstLetterOfWords(props.getValue()),
    header: "Type",
  }),
  columnHelper.display({
    cell: ({ row }) => (
      <BoLinkButton
        to={campaignOffersPath(row.original.id)}
        variant="secondary"
        size="extraSmall"
      >
        Manage Offers
      </BoLinkButton>
    ),
    header: "Actions",
    meta: {
      fitContent: true,
    },
  }),
];

export const CampaignsPage = () => {
  const flash = useRhFlash();

  const campaignFilterValues = useAtomValue(campaignSearchFiltersAtom);

  // Controlled table state
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });

  const campaignsQuery = useCampaignsQuery({
    queryOptions: {
      enabled: true,
      keepPreviousData: true,
    },
    searchOptions: {
      ...campaignFilterValues,
      limit: pageSize,
      // React Table stores pageIndex as page number (0 indexed) so we multiply by pageSize to get our offset for the api
      offset: pageIndex * pageSize,
    },
  });

  useEffect(() => {
    if (campaignsQuery.error) {
      flash.error("Error getting campaigns. Please refresh page.");
    }
  }, [campaignsQuery.error, flash]);

  if (campaignsQuery.isPending) {
    return (
      <CampaignsPageLayout>
        <BoCircularProgress />
      </CampaignsPageLayout>
    );
  }

  if (campaignsQuery.isError) {
    return (
      <CampaignsPageLayout>
        <Body1>No Campaigns Available</Body1>
      </CampaignsPageLayout>
    );
  }

  const pageCount = campaignsQuery.data?.count
    ? Math.ceil(campaignsQuery.data.count / pageSize)
    : 0;

  return (
    <CampaignsPageLayout>
      <CampaignSearchForm />

      <BoReactTable<CampaignType>
        data={campaignsQuery.data.results}
        columns={columns}
        tableCaption="Campaign Table"
        pagination
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
      />
    </CampaignsPageLayout>
  );
};
