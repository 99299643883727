import { useOktaAuth } from "@okta/okta-react";
import { LoggedInRoutes } from "@pricing/routes/LoggedIn.route";
import { LoggedOutRoutes } from "@pricing/routes/LoggedOut.route";
import { campaignsPath, signInPath } from "@pricing/routes/routePaths";
import { withSentryReactRouterV6Routing as SentryWithSentryReactRouterV6Routing } from "@sentry/react";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-routes--component
const SentryRoutes = SentryWithSentryReactRouterV6Routing(Routes);

const IndexRouteToLandingPageHandler = () => {
  const { authState } = useOktaAuth();

  if (!authState) {
    // If we don't have an authState, we don't know if we're logged in or not.
    // We'll wait for the authState to be set before we redirect.
    return null;
  }

  const { isAuthenticated } = authState;

  if (isAuthenticated) {
    return <Navigate to={campaignsPath()} />;
  }
  return <Navigate to={signInPath()} />;
};

export const PricingRoutes = () => {
  return (
    <SentryRoutes>
      <Route index element={<IndexRouteToLandingPageHandler />} />
      <Route
        path="*"
        element={
          <>
            <LoggedInRoutes />
            <LoggedOutRoutes />
          </>
        }
      />
    </SentryRoutes>
  );
};
