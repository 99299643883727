import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { PremiseRenewalsSearchType } from "@pricing-utils/types/renewalsTypes";
import React, { useState } from "react";
import styled from "styled-components";

const StyledBoTable = styled(BoTable)`
  margin-bottom: ${rhOpsSpacingPx(3)};
  margin-top: ${rhOpsSpacingPx(2)};
  min-height: 100%;
  min-width: 100%;
`;

const ExpandButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${rhOpsSpacingPx(3)};
  width: 100%;
`;

interface PremiseTableProps {
  premises: PremiseRenewalsSearchType[];
}

const tableHeaders = [
  "Customer ID",
  "Premise ID",
  "Utility",
  "Order ID",
  "Plan Type",
  "Current Price Paid",
  "Contract Exp. Date",
  "Status",
  "60 Days",
  "30 Days",
  "Contract Expiration",
  "Portal Renewals",
];

const PremiseHeader = () => (
  <BoTableHead>
    <BoTableRow>
      {tableHeaders.map((header) => (
        <BoTableHeadCell $align="left" key={header}>
          {header}
        </BoTableHeadCell>
      ))}
    </BoTableRow>
  </BoTableHead>
);

interface PremiseBodyRowProps {
  premise: PremiseRenewalsSearchType;
}

export const PremiseBodyRow = ({ premise }: PremiseBodyRowProps) => {
  // If the currentOrder for a premise doesn't exist no renewal offers are returned
  const renewalOffers = premise.renewalOffers ?? [];

  const offer60Days = renewalOffers.find(
    (offer) => offer.daysToExpiration === 60
  );
  const offer30Days = renewalOffers.find(
    (offer) => offer.daysToExpiration === 30
  );
  const offerExpiration = renewalOffers.find(
    (offer) => offer.daysToExpiration === 0
  );

  return (
    <BoTableRow>
      <BoTableCell>{premise.customerId}</BoTableCell>
      <BoTableCell>{premise.id}</BoTableCell>
      <BoTableCell>{premise.utilityName}</BoTableCell>
      <BoTableCell>{premise.activeOrderId}</BoTableCell>
      <BoTableCell>{premise.planTitle}</BoTableCell>
      <BoTableCell $align="center">
        {formatCurrency(Number(premise.rhythmKwhRate))}
      </BoTableCell>
      <BoTableCell>
        {formatMonthDayYear(premise.contractEndDate ?? "")}
      </BoTableCell>
      <BoTableCell>{premise.orderStatus}</BoTableCell>
      <BoTableCell>{stringOrEmDash(offer60Days?.offerTitle)}</BoTableCell>
      <BoTableCell>{stringOrEmDash(offer30Days?.offerTitle)}</BoTableCell>
      <BoTableCell>{stringOrEmDash(offerExpiration?.offerTitle)}</BoTableCell>
      <BoTableCell>{stringOrEmDash(premise.renewalCampaign)}</BoTableCell>
    </BoTableRow>
  );
};

export const PremiseTable = ({ premises }: PremiseTableProps) => {
  const [showFullResults, setShowFullResults] = useState(false);

  const handleShowFullClick = () => {
    setShowFullResults((prevShowFullResults) => !prevShowFullResults);
  };

  const tableRows = showFullResults ? premises : premises.slice(0, 10);

  return (
    <>
      <StyledBoTable aria-label="Premise Table">
        <PremiseHeader />
        <BoTableBody>
          {tableRows.map((premise) => (
            <PremiseBodyRow premise={premise} key={`premise-${premise.id}`} />
          ))}
        </BoTableBody>
      </StyledBoTable>
      {premises.length > 10 && !showFullResults && (
        <ExpandButtonContainer>
          <BoButton variant="secondary" onClick={handleShowFullClick}>
            View All Results
          </BoButton>
        </ExpandButtonContainer>
      )}
    </>
  );
};
