import { pricingApi } from "@pricing-data/api/pricingApi";
import { OfferQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { OfferSearchOptions } from "@pricing-utils/types/offerTypes";
import {
  keepPreviousData as reactQueryKeepPreviousData,
  useQuery,
} from "@tanstack/react-query";

interface QueryOptions {
  enabled?: boolean;
  keepPreviousData?: boolean;
}
interface UseOffersProps {
  queryOptions?: QueryOptions;
  searchOptions?: OfferSearchOptions;
}

export const useOffers = ({
  queryOptions = {},
  searchOptions = {},
}: UseOffersProps) => {
  return useQuery({
    enabled:
      typeof queryOptions?.enabled !== "undefined"
        ? queryOptions.enabled
        : true,
    placeholderData: queryOptions?.keepPreviousData
      ? reactQueryKeepPreviousData
      : undefined,
    queryFn: () => pricingApi.offers.list(searchOptions),
    queryKey: OfferQueryKeys.LIST(searchOptions),
  });
};
