import { pricingApi } from "@pricing-data/api/pricingApi";
import { CampaignQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateCampaignMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: pricingApi.campaigns.create,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: CampaignQueryKeys.CAMPAIGNS(),
      });
    },
  });
};
