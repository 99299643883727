import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as CircledGreenCheck } from "@design-system/icons/CircledGreenCheck.svg";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoFileField } from "@ops-design-system/components/BoFileField/BoFileField";
import { BoTextButton } from "@ops-design-system/components/BoTextButton/BoTextButton";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { darkPurple } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { ChangeEvent, useRef } from "react";
import { useForm } from "react-final-form";
import styled from "styled-components";

const StyledFileConfirmationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(1)};
`;

const StyledSubtitle2 = styled(Subtitle2)`
  white-space: nowrap;
`;

const StyledBoTextButton = styled(BoTextButton)`
  color: ${darkPurple["500"]};
`;

export const PremiseIdCSVUpload = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const flash = useRhFlash();

  const form = useForm();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event && event.target.files?.length) {
      const file = event?.target.files[0];
      const { name } = file;
      const pattern = /.csv$/i;

      if (pattern.test(name) === false) {
        flash.error("File upload must be a CSV File");
        form.change("premiseIdsFile", undefined);
        return;
      }
      form.change("premiseIdsFile", file);
      flash.success("CSV File Added");
    }
  };

  const clearFile = () => {
    form.change("premiseIdsFile", undefined);
  };

  const csvFileName = form.getFieldState("premiseIdsFile")?.value?.name;

  return (
    <>
      <BoFileField
        name="premiseIdsFile"
        id="premiseIdInput"
        style={{ display: "none" }}
        onChange={handleChange}
        forwardRef={fileInputRef}
        // Because this input is hidden, we need to set an aria label
        aria-label="Premise CSV Input"
        accept=".csv"
      />
      <BoButton onClick={() => fileInputRef.current?.click()}>
        Select Premise IDs CSV
      </BoButton>
      {csvFileName && (
        <StyledFileConfirmationContainer>
          <CircledGreenCheck height={16} width={16} />
          <StyledSubtitle2>
            {csvFileName} Successfully Uploaded.
          </StyledSubtitle2>
          <StyledBoTextButton onClick={clearFile}>Remove</StyledBoTextButton>
        </StyledFileConfirmationContainer>
      )}
    </>
  );
};
