import { CsvSegmentation } from "@pricing/components/PremiseSegmentation/CsvSegmentation/CsvSegmentation";
import { FilterSegmentation } from "@pricing/components/PremiseSegmentation/FilterSegmentation/FilterSegmentation";
import { PremiseRenewalTabs } from "@pricing/components/PremiseSegmentation/PremiseRenewalTabs/PremiseRenewalTabs";
import { StyledRenewalsPage } from "@pricing/pages/Renewals/shared/StyledRenewalsPage";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const PremiseSegmentationPage = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hash) {
      navigate("#filterSearch");
      return;
    }

    if (hash !== "#filterSearch" && hash !== "#csvSearch") {
      navigate("#filterSearch");
    }
  }, [hash, navigate]);

  return (
    <StyledRenewalsPage pageName="Renewals - Premises">
      <PremiseRenewalTabs />
      {hash === "#csvSearch" && <CsvSegmentation />}
      {hash === "#filterSearch" && <FilterSegmentation />}
    </StyledRenewalsPage>
  );
};
