import { useModalState } from "@common/hooks/useModalState";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { zodResolver } from "@hookform/resolvers/zod";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDialogHeader } from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { useUpdateOffers } from "@pricing-data/hooks/mutations/useUpdateOffers";
import { usePlan } from "@pricing-data/hooks/queries/usePlan";
import {
  OfferBulkUpdateRequestType,
  OfferType,
  PriceMethodTypes,
} from "@pricing-utils/types/offerTypes";
import { PlanType } from "@pricing-utils/types/planTypes";
import {
  DateFields,
  PriceFields,
  SolarFields,
} from "@pricing/components/OfferBulkUpdateForm/FormFields";
import {
  OfferBulkUpdateFormValues,
  bulkUpdateFormValidation,
} from "@pricing/components/OfferBulkUpdateForm/formSchema";
import {
  Form,
  FormFieldSet,
  FormFieldsContainer,
  ModalFooter,
  SidebarContainer,
  StyledButton,
} from "@pricing/components/OfferBulkUpdateForm/OfferBulkUpdateForm.styles";
import { SelectedOffersTable } from "@pricing/components/OfferBulkUpdateForm/SelectedOffersTable";
import { SidebarInfo } from "@pricing/components/OfferBulkUpdateForm/SidebarInfo";
import { calculateEnergyRateMWH } from "@pricing/utils/calculations.util";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

interface OfferBulkUpdateFormProps {
  offers: OfferType[];
  planId: IdType;
}

interface UpdateFormProps {
  closeModal: () => void;
  offers: OfferType[];
  plan: PlanType;
}

const UpdateForm = (props: UpdateFormProps) => {
  const { plan, offers, closeModal } = props;

  const updateOffersMutation = useUpdateOffers();
  const flash = useRhFlash();

  const formMethods = useForm<OfferBulkUpdateFormValues>({
    defaultValues: {
      earliestStartDate: "",
      endDate: "",
      grossMarginPrice: undefined,
      price2000Kwh: undefined,
      priceMethod: undefined,
      solarBuybackRate: undefined,
      startDate: "",
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: zodResolver(bulkUpdateFormValidation),
  });

  const handleClose = () => {
    formMethods.reset();
    closeModal();
  };

  const onSubmit = (values: OfferBulkUpdateFormValues) => {
    let params: OfferBulkUpdateRequestType = {
      offers: offers.map((o) => o.id),
      planId: plan.id,
    };

    if (values.priceMethod === PriceMethodTypes.Fixed && values.price2000Kwh) {
      params.pricePerMwh = calculateEnergyRateMWH({
        plan,
        price: values.price2000Kwh,
      }).toString();
      params.priceMethod = values.priceMethod;
    } else if (
      values.priceMethod === PriceMethodTypes.Margin &&
      values.grossMarginPrice
    ) {
      params.grossMargin = values.grossMarginPrice.toString();
      params.priceMethod = values.priceMethod;
    }

    if (plan.solarEligible && values.solarBuybackRate) {
      params.solarBuybackKwhRate = values.solarBuybackRate.toString();
    }

    params = {
      ...params,
      ...(values.startDate && {
        startDate: values.startDate,
      }),
      ...(values.endDate && {
        endDate: values.endDate,
      }),
      ...(values.earliestStartDate && {
        earliestServiceStartDate: values.earliestStartDate,
      }),
    };

    updateOffersMutation.mutate(params, {
      onError: (error) => {
        flash.error(JSON.stringify(error.data.errors));
      },
      onSuccess: () => {
        flash.success("Offers successfully updated");
        formMethods.reset();
      },
    });
  };

  const {
    formState: { isDirty, isSubmitting },
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <SidebarContainer>
          <SidebarInfo plan={plan} />
        </SidebarContainer>

        <FormFieldsContainer>
          <SelectedOffersTable offers={offers} />

          <FormFieldSet>
            <legend>Prices:</legend>
            <PriceFields plan={plan} />
          </FormFieldSet>

          <FormFieldSet>
            <legend>Dates:</legend>
            <DateFields />
          </FormFieldSet>
          {plan.solarEligible ? (
            <FormFieldSet>
              <legend>Solar:</legend>
              <SolarFields />
            </FormFieldSet>
          ) : null}
        </FormFieldsContainer>
        <ModalFooter>
          <BoButton variant="secondary" type="button" onClick={handleClose}>
            Close
          </BoButton>
          <BoButton
            type="submit"
            disabled={!isDirty || isSubmitting || offers.length === 0}
          >
            Submit
          </BoButton>
        </ModalFooter>
      </Form>
    </FormProvider>
  );
};

export const OfferBulkUpdateForm = ({
  offers,
  planId,
}: OfferBulkUpdateFormProps) => {
  const planQuery = usePlan({ planId });
  const { isOpen, open, close } = useModalState();

  const plan = planQuery.data;

  if (planQuery.isPending || planQuery.isError || !plan) {
    return (
      <StyledButton type="button" disabled>
        Update Offers
      </StyledButton>
    );
  }

  return (
    <>
      <StyledButton
        type="button"
        onClick={open}
        disabled={offers.length === 0 || !plan}
      >
        Update Offers
      </StyledButton>
      {isOpen ? (
        <BoModal open size="large" onClose={close}>
          <BoDialogHeader>Bulk Update Offers</BoDialogHeader>
          <UpdateForm plan={plan} offers={offers} closeModal={close} />
        </BoModal>
      ) : null}
    </>
  );
};
