import { IdType } from "@common/types/apiTypes";
import { UtilityType } from "@pricing-utils/types/utilityTypes";

export const PlanFieldNames = [
  "title",
  "description",
  "termMonths",
  "zuoraRatePlanId",
] as const;
export type PlanFieldNameType = (typeof PlanFieldNames)[number];

export interface PlanType {
  annualVolumeMwh: number;
  archived: boolean;
  autopayPaperlessMonthlyDiscount: string | null;
  baseChargeAmount: string;
  bundled: boolean;
  descriptionEn: string;
  descriptionEs: string;
  endDate: string;
  etfAmount: number;
  id: IdType;
  isGreen: boolean;
  loadProfile: string;
  longDescriptionEn: string;
  longDescriptionEs: string;
  pricingType: PlanPricingTypes;
  product: string;
  recProduct: string;
  solarEligible: boolean;
  solarGenerationCapped: boolean;
  solarGenerationToConsumptionRatio: number | null;
  startDate: string;
  tdspCustomerChargeAmount: string;
  tdspPerUnitMwhAmount: string;
  termMonths: number;
  termVolumeMwh: string;
  termedCogsAmountMwh: string;
  thermostatManagementMonthlyDiscount: string | null;
  title: string;
  utility: UtilityType;
  zuoraRatePlanId: IdType;
}

export enum PlanOrderOptions {
  "Id: High to Low" = "-id",
  "Id: Low to High" = "id",
  "Title A->Z" = "title",
  "Title Z->A" = "-title",
}

export interface PlanRequestType {
  archived?: boolean;
  ordering?: PlanOrderOptions;
  pricingType?: PlanPricingTypes;
  utilityId?: string;
}

export type PlanResponseType = PlanType;

export type PlansResponseType = PlanType[];

export interface PlanUploadResponseType {
  name: string;
  size: number;
}

export interface UtilitySearchRequestType {
  zipCode: string;
}
export type UtilitySearchResponseType = UtilityType[];

export interface UpdatePlanArchiveType {
  archived: boolean;
}

export enum PlanPricingTypes {
  FIXED_RATE = "FIXED_RATE",
  TIME_OF_USE = "TIME_OF_USE",
}

export interface PlanSearchOptions {
  archived?: boolean;
  ordering?: PlanOrderOptions;
  pricingType?: PlanPricingTypes;
  utilityId: IdType;
}
