import { OffersBulkUpdate } from "@pricing/components/OffersBulkUpdate/OffersBulkUpdate";
import { StyledOfferPage } from "@pricing/pages/Offers/shared/StyledOffersPage";
import React from "react";

export const OffersBulkUpdatePage = () => {
  return (
    <StyledOfferPage pageName="Offers - Bulk Update">
      <OffersBulkUpdate />
    </StyledOfferPage>
  );
};
