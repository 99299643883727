import {
  CampaignOrderOptions,
  CampaignSearchFilters,
} from "@pricing-utils/types/campaignTypes";
import { atomWithReset } from "jotai/utils";

export const campaignSearchFiltersAtom = atomWithReset<CampaignSearchFilters>({
  active: true,
  ordering: CampaignOrderOptions["Campaign Slug A->Z"],
});
