import { EM_DASH } from "@common/constants/characters.constant";
import { IdType } from "@common/types/apiTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { useOfferQuery } from "@pricing-data/hooks/queries/useOffer.query";
import { DefinitionList } from "@pricing/pages/OfferPage/OfferPage.styled";
import { StyledOfferPage } from "@pricing/pages/Offers/shared/StyledOffersPage";
import { campaignOffersPath } from "@pricing/routes/routePaths";
import { ADMIN_URL } from "@pricing/settings/config";
import React, { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";

const DefinitionItem = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => {
  return (
    <div>
      <Body1 as="dt" id={title} $fontWeight="Bold">
        {title}
      </Body1>
      <Body1 as="dd" aria-labelledby={title}>
        {children || EM_DASH}
      </Body1>
    </div>
  );
};

const AdminLink = (offerId: IdType) => {
  return (
    <BoLink href={`${ADMIN_URL}/pricing/offers/${offerId}/`} target="_blank">
      View in Admin
    </BoLink>
  );
};

export const OfferPage = () => {
  const { id } = useParams<{ id: IdType }>();

  const offerQuery = useOfferQuery({
    offerId: id,
  });

  if (offerQuery.isPending) {
    return (
      <StyledOfferPage>
        <BoCircularProgress />
      </StyledOfferPage>
    );
  }

  if (offerQuery.isError) {
    return (
      <StyledOfferPage>
        <Body1>Error fetching offer</Body1>
      </StyledOfferPage>
    );
  }

  const offer = offerQuery.data;

  return (
    <StyledOfferPage
      returnLinkTo={campaignOffersPath(offer.campaignId)}
      returnLinkText={`< Back to ${offer.campaignSlug} campaign`}
      pageName={offer.title}
      subTitle={AdminLink(id)}
    >
      <DefinitionList as="dl">
        <DefinitionItem title="Offer Id">{offer.id}</DefinitionItem>
        <DefinitionItem title="Active">
          {offer.isActive ? "Yes" : "No"}
        </DefinitionItem>
        <DefinitionItem title="Utility">{offer.utilityName}</DefinitionItem>
        <DefinitionItem title="Campaign">{offer.campaignSlug}</DefinitionItem>
        <DefinitionItem title="Plan Title">{offer.title}</DefinitionItem>
        <DefinitionItem title="Plan Id">{offer.planId}</DefinitionItem>
        <DefinitionItem title="Start Date">{offer.startDate}</DefinitionItem>
        <DefinitionItem title="Earliest Service Start Date">
          {offer.earliestServiceStartDate}
        </DefinitionItem>
        <DefinitionItem title="Max Service Start Date Days">
          {offer.maxServiceStartDateDays}
        </DefinitionItem>
        <DefinitionItem title="End Date">{offer.endDate}</DefinitionItem>
        <DefinitionItem title="Offer Labels">
          {offer.offerLabels?.join(", ") ?? ""}
        </DefinitionItem>
        <DefinitionItem title="Price @ 500kWh">
          {offer.price500Kwh}
        </DefinitionItem>
        <DefinitionItem title="Price @ 1000kWh">
          {offer.price1000Kwh}
        </DefinitionItem>
        <DefinitionItem title="Price @ 2000kWh">
          {offer.price2000Kwh}
        </DefinitionItem>
        <DefinitionItem title="Price per mWh">
          {offer.pricePerMwh}
        </DefinitionItem>
        <DefinitionItem title="Gross Margin">
          {offer.grossMargin}
        </DefinitionItem>
        <DefinitionItem title="TDSP kWh Rate">
          {offer.tdspKwhRate}
        </DefinitionItem>
        <DefinitionItem title="TDSP Monthly Charge">
          {offer.tdspMonthlyCharge}
        </DefinitionItem>
        <DefinitionItem title="Base Charge Amount">
          {offer.baseChargeAmount}
        </DefinitionItem>

        <DefinitionItem title="Promo Code">{offer.promo?.code}</DefinitionItem>
        <DefinitionItem title="Tags">
          {offer?.tags?.calloutColor
            ? `Color: ${offer.tags.calloutColor} | EN: ${offer.tags.calloutEn} | ES: ${offer.tags.calloutEs}`
            : ""}
        </DefinitionItem>
        <DefinitionItem title="Priority">{offer.priority}</DefinitionItem>
        <DefinitionItem title="Solar Eligible">
          {offer.solarEligible ? "Yes" : "No"}
        </DefinitionItem>
        <DefinitionItem title="Solar Buyback kWh Rate">
          {offer.solarBuybackKwhRate}
        </DefinitionItem>
        <DefinitionItem title="Solar Credit kWh Rate">
          {offer.solarCreditKwhRate}
        </DefinitionItem>
        <DefinitionItem title="Price Method">
          {offer.priceMethod}
        </DefinitionItem>
        <DefinitionItem title="Pricing Type">
          {offer.pricingType}
        </DefinitionItem>
        <DefinitionItem title="Term Months">{offer.termMonths}</DefinitionItem>
        <DefinitionItem title="Grace Period Days">
          {offer.gracePeriodDays}
        </DefinitionItem>
        <DefinitionItem title="Auto Pay & Paperless Discount">
          {offer.autopayPaperlessMonthlyDiscount}
        </DefinitionItem>
        <DefinitionItem title="Thermostat Discount">
          {offer.thermostatManagementMonthlyDiscount}
        </DefinitionItem>
      </DefinitionList>

      <DefinitionList $columns={2}>
        <DefinitionItem title="Description">
          {offer.descriptionEn}
        </DefinitionItem>
        <DefinitionItem title="Spanish Description">
          {offer.descriptionEs}
        </DefinitionItem>
        <DefinitionItem title="Long Description">
          {offer.longDescriptionEn}
        </DefinitionItem>
        <DefinitionItem title="Spanish Long Description">
          {offer.longDescriptionEs}
        </DefinitionItem>
      </DefinitionList>
    </StyledOfferPage>
  );
};
