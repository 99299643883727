import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const Form = styled.form`
  display: grid;
  gap: ${rhOpsSpacingPx(5)} ${rhOpsSpacingPx(1)};
  grid-template-areas:
    "segmentationType utility . ."
    "offerTitle . . offersTable"
    "campaign . . offersTable"
    "offer60 offer30 offerExpiration offersTable"
    "myAccountTitle . . ."
    "myAccount . . ."
    "submit . . .";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-content: center;
`;

export const SubmitButton = styled(BoButton)`
  align-self: flex-end;
  grid-area: submit;
  height: 40px;
`;

export const TableContainer = styled.div`
  align-self: flex-start;
  grid-area: offersTable;
`;
