import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import {
  BoTable,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { trueWhite } from "@ops-design-system/styles/palette.colors";
import { OfferType } from "@pricing-utils/types/offerTypes";
import styled from "styled-components";

export const StyledTable = styled(BoReactTable<OfferType>)`
  overflow: auto;
  & ${BoTable} ${BoTableHead} ${BoTableRow} ${BoTableHeadCell} {
    position: sticky;
    top: 0;
  }
  & ${BoTable} ${BoTableHeadCell} {
    background-color: ${trueWhite};
  }

  & ${BoTableCell} {
    text-align: center;
    white-space: nowrap;
    width: 1%;
  }
`;
