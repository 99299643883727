import { RhApiError } from "@common/types/errorTypes";
import { isDefined } from "@common/utils/arrayUtils";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButtonWithConfirmDialog } from "@ops-design-system/components/BoButtonWithConfirmDialog/BoButtonWithConfirmDialog";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { pricingApi } from "@pricing-data/api/pricingApi";
import {
  CampaignStatusType,
  CampaignType,
} from "@pricing-utils/types/campaignTypes";
import { PremiseRenewalsSearchType } from "@pricing-utils/types/renewalsTypes";
import { ConfirmModal } from "@pricing/components/PremiseSegmentation/ConfirmModal/ConfirmModal";
import { RenewalSegmentationWarnings } from "@pricing/constants/renewalSegmentation.constant";
import React from "react";
import { Form } from "react-final-form";

const myAccountCampaignInputName = "myAccountCampaign";

interface FormValues {
  [myAccountCampaignInputName]: string;
}

export interface CampaignSelectionProps {
  campaigns: CampaignType[];
  onSelectionSuccess: () => void;
  selectedPremises: PremiseRenewalsSearchType[];
  warnings: RenewalSegmentationWarnings[];
}

export const CampaignSelection = ({
  campaigns,
  selectedPremises,
  warnings,
  onSelectionSuccess,
}: CampaignSelectionProps) => {
  const flash = useRhFlash();

  async function submitHandler(values: FormValues) {
    const campaignId = values[myAccountCampaignInputName];

    if (!campaignId) {
      return;
    }

    const orderIds = selectedPremises
      .map((premise) => premise.activeOrderId)
      .filter(isDefined);

    // While we don't ever expect to not have orderIds from the premise data, we'll
    // handle it gracefully if we do.
    if (!orderIds.length) {
      flash.error(
        "Error when trying to set campaign; no active order IDs found for any premises."
      );
      return;
    }

    try {
      await pricingApi.renewals.setRenewalCampaign({
        campaign: campaignId,
        orders: orderIds,
      });
    } catch (error) {
      if ((error as RhApiError).status === 400) {
        flash.error(
          "An error occurred while trying to set the linked orders for the selected campaign."
        );
      } else {
        flash.error(
          (error as Error)?.message ?? error ?? "An unknown error occurred."
        );
      }
      return;
    }
    flash.success(
      "Successfully applied the portal campaign to the listed premises. The customer will see the offers from the campaign when they renew through the Portal."
    );
    onSelectionSuccess();
  }

  return (
    <Form<FormValues>
      onSubmit={(values) => submitHandler(values)}
      render={({ handleSubmit, pristine }) => (
        <BoGrid $variant={GridVariants.Grid1}>
          <BoGridColumn>
            <BoSelectField
              name={myAccountCampaignInputName}
              label="Campaign for Portal Renewals"
              options={campaigns
                .filter(
                  (campaign) => campaign.status !== CampaignStatusType.expired
                )
                .map((campaign) => ({
                  label: campaign.campaignSlug,
                  value: campaign.id,
                }))}
            />
            <BoButtonWithConfirmDialog
              header="Confirm Renewal Campaign"
              message={
                <ConfirmModal warnings={warnings} premises={selectedPremises} />
              }
              handleConfirm={handleSubmit}
              buttonContent="Apply Campaign"
              variant="primary"
              buttonFullWidth={false}
            />
          </BoGridColumn>
        </BoGrid>
      )}
    />
  );
};
