import React from "react";
import { Field } from "react-final-form";

interface BoFileFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  forwardRef?: React.Ref<HTMLInputElement>;
  name: string;
}

export const BoFileField = ({
  name,
  forwardRef,
  ...props
}: BoFileFieldProps) => (
  <Field<FileList> name={name}>
    {({ input: { value, onChange, ...input } }) => (
      <input
        {...input}
        ref={forwardRef}
        type="file"
        onChange={({ target }) => onChange(target.files)} // instead of the default target.value
        {...props}
      />
    )}
  </Field>
);
