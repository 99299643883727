import { useOktaAuth } from "@okta/okta-react";
import { CampaignOffersPage } from "@pricing/pages/CampaignOffersPage/CampaignOffersPage";
import { CampaignPromosPage } from "@pricing/pages/CampaignPromosPage/CampaignPromosPage";
import { CampaignsPage } from "@pricing/pages/CampaignsPage/CampaignsPage";
import { OfferPage } from "@pricing/pages/OfferPage/OfferPage";
import { OfferPromosPage } from "@pricing/pages/OfferPromosPage/OfferPromosPage";
import { OffersBulkUpdatePage } from "@pricing/pages/Offers/OffersBulkUpdatePage/OffersBulkUpdatePage";
import { OffersManagementPage } from "@pricing/pages/Offers/OffersManagementPage/OffersManagementPage";
import { PlansPage } from "@pricing/pages/PlansPage/PlansPage";
import { OffersSegmentationPage } from "@pricing/pages/Renewals/OffersSegmentationPage/OffersSegmentationPage";
import { PremiseSegmentationPage } from "@pricing/pages/Renewals/PremiseSegmentationPage/PremiseSegmentationPage";
import { RenewalsManagementPage } from "@pricing/pages/Renewals/RenewalsManagementPage/RenewalsManagementPage";
import {
  campaignOffersPath,
  campaignPromosPath,
  campaignsPath,
  logoutPath,
  offerPagePath,
  offerPromosPath,
  offersBulkUpdatePath,
  offersManagementPath,
  plansPath,
  renewalsManagementPath,
  renewalsOffersSegmentationPath,
  renewalsPremiseSegmentationPath,
  signInPath,
} from "@pricing/routes/routePaths";
import { withSentryReactRouterV6Routing as SentryWithSentryReactRouterV6Routing } from "@sentry/react";
import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

// See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-routes--component
const SentryRoutes = SentryWithSentryReactRouterV6Routing(Routes);

export const LogOut = () => {
  const { oktaAuth } = useOktaAuth();

  oktaAuth.signOut();
  return null;
};

const LoggedInOnly = () => {
  const { authState } = useOktaAuth();

  if (!authState) {
    // If there is no authState, we don't know if the user is authenticated or not.
    // We return null and wait for the authState to be initialized.
    return null;
  }

  if (!authState.isAuthenticated) {
    return <Navigate to={signInPath()} />;
  }

  return <Outlet />;
};

export const LoggedInRoutes = () => {
  return (
    <SentryRoutes>
      <Route element={<LoggedInOnly />}>
        <Route path={campaignsPath()} element={<CampaignsPage />} />
        <Route
          path={campaignOffersPath(":id")}
          element={<CampaignOffersPage />}
        />
        <Route path={offerPagePath(":id")} element={<OfferPage />} />
        <Route path={campaignPromosPath()} element={<CampaignPromosPage />} />
        <Route
          path={offersManagementPath()}
          element={<OffersManagementPage />}
        />
        <Route
          path={offersBulkUpdatePath()}
          element={<OffersBulkUpdatePage />}
        />
        <Route path={plansPath()} element={<PlansPage />} />
        <Route path={offerPromosPath()} element={<OfferPromosPage />} />
        <Route
          path={renewalsManagementPath()}
          element={<RenewalsManagementPage />}
        />
        <Route
          path={renewalsPremiseSegmentationPath()}
          element={<PremiseSegmentationPage />}
        />
        <Route
          path={renewalsOffersSegmentationPath()}
          element={<OffersSegmentationPage />}
        />
        <Route path={logoutPath()} element={<LogOut />} />
      </Route>
    </SentryRoutes>
  );
};
