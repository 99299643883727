import {
  ActionContainer,
  Header,
  RenewalsPageContainer,
  ReturnLinkWrapper,
  StyledLink,
  StyledPage,
  StyledRenewalsPageDivider,
  Title,
} from "@pricing/pages/Renewals/shared/StyledRenewalsPage.styled";
import { renewalsManagementPath } from "@pricing/routes/routePaths";
import React, { PropsWithChildren } from "react";

interface StyledAdminPageProps {
  headerComponent?: React.ReactNode;
  pageName?: string;
  returnLinkText?: string;
  returnLinkTo?: string;
}

export const StyledRenewalsPage = ({
  returnLinkTo,
  returnLinkText,
  pageName,
  children,
  headerComponent,
}: PropsWithChildren<StyledAdminPageProps>) => {
  return (
    <StyledPage>
      <RenewalsPageContainer>
        <Header>
          <ReturnLinkWrapper>
            <StyledLink to={returnLinkTo ?? renewalsManagementPath()}>
              {returnLinkText ?? "< Renewals"}
            </StyledLink>
          </ReturnLinkWrapper>
          <Title $fontWeight="SemiBold">{pageName}</Title>
          {headerComponent ? (
            <ActionContainer>{headerComponent}</ActionContainer>
          ) : null}
        </Header>

        <StyledRenewalsPageDivider />
        {children}
      </RenewalsPageContainer>
    </StyledPage>
  );
};
