import { BoSelectOptionType } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { ReactComponent as DropdownArrow } from "@ops-design-system/icons/DropdownArrow.svg";
import {
  activeBorders,
  borders,
  errorBorders,
} from "@ops-design-system/styles/borders";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { PropsWithChildren } from "react";
import {
  DropdownIndicatorProps,
  GroupBase,
  StylesConfig,
  components as reactSelectComponents,
} from "react-select";

export enum SelectVariants {
  MultiSelect = "MultiSelect",
}

const borderBottom = (hasErrors?: boolean, isFocused?: boolean) => {
  if (hasErrors) {
    return errorBorders["500"];
  } else if (isFocused) {
    return activeBorders["500"];
  } else {
    return borders["300"];
  }
};

const borderExpand = (hasErrors?: boolean, isFocused?: boolean) => {
  if (isFocused || hasErrors) {
    return activeBorders["500"];
  }
};

export const styles = (
  hasErrors?: boolean
): StylesConfig<
  BoSelectOptionType,
  boolean,
  GroupBase<BoSelectOptionType>
> => ({
  container: (provided, props) => {
    return {
      ...provided,
      borderBottom: borderExpand(hasErrors, props.isFocused ?? false),
      paddingTop: `${rhOpsSpacingPx(1)}`,
    };
  },
  control: (provided, props) => ({
    ...provided,
    "&:hover": {},
    borderBottom: borderBottom(hasErrors, props.isFocused),
    borderLeft: "none",
    borderRadius: "none",
    borderRight: "none",
    borderTop: "none",
    boxShadow: "none",
    fontSize: `${rhOpsSpacingPx(2)}`,
    paddingBottom: "4px",
  }),
  dropdownIndicator: (provided, props) => {
    const color = props.isFocused ? darkPurple["500"] : grey["900"];

    return {
      ...provided,
      "&:hover": {
        color,
      },
      color,
    };
  },
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  option: (provided) => ({
    ...provided,
    color: darkPurple["900"],
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 0",
  }),
});

export const components = {
  DropdownIndicator: (
    props: PropsWithChildren<
      DropdownIndicatorProps<
        BoSelectOptionType,
        true,
        GroupBase<BoSelectOptionType>
      >
    >
  ) => (
    <reactSelectComponents.DropdownIndicator {...props}>
      <DropdownArrow />
    </reactSelectComponents.DropdownIndicator>
  ),
};
