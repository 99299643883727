import { IdType } from "@common/types/apiTypes";
import { pricingApi } from "@pricing-data/api/pricingApi";
import { OfferQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseOfferQueryProps {
  offerId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}
export const useOfferQuery = (props: UseOfferQueryProps) => {
  const { offerId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => pricingApi.offers.retrieve(offerId),
    queryKey: OfferQueryKeys.RETRIEVE(offerId),
  });
};
