import {
  components,
  styles,
} from "@ops-design-system/components/BoMultiSelectField/BoMultiSelect.styled";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { useState } from "react";
import { useField } from "react-final-form";
import Select from "react-select";
import styled from "styled-components";

export interface BoSelectOptionType {
  label: JSX.Element | string;
  value: string | number | null;
}

interface BoMultiSelectFieldProps {
  className?: string;
  disabled?: boolean;
  label: string;
  name: string;
  options: BoSelectOptionType[];
}

const BoSelectWrapper = styled.div`
  width: 100%;
`;

const StyledError = styled(Body2).attrs({ as: "div" })`
  color: ${red.main};
  padding-top: ${rhOpsSpacingPx(1)};
`;

export const BoMultiSelectField = ({
  name,
  label,
  options,
  disabled = false,
  className,
}: BoMultiSelectFieldProps) => {
  const { input, meta } = useField(name);

  const labelId = `multi-select-field__${name}`;

  const hasErrors =
    (meta.touched && Boolean(meta.error)) ||
    (meta.submitFailed && Boolean(meta.submitError));

  const [focused, setFocused] = useState(false);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <BoSelectWrapper className={className}>
      <BoLabel id={labelId} htmlFor={name} $isFocused={focused}>
        {label}
      </BoLabel>
      <Select
        {...input}
        options={options}
        isMulti
        aria-labelledby={labelId}
        styles={styles(hasErrors)}
        components={components}
        isSearchable={false}
        onFocus={onFocus}
        onBlur={onBlur}
        isDisabled={disabled}
      />
      {hasErrors && <StyledError>{meta.error || meta.submitError}</StyledError>}
    </BoSelectWrapper>
  );
};
