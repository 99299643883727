import {
  Body1,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { PremiseRenewalsSearchType } from "@pricing-utils/types/renewalsTypes";
import { RenewalSegmentationWarnings } from "@pricing/constants/renewalSegmentation.constant";
import React from "react";
import styled from "styled-components";

const WarningItem = styled(Body1).attrs({ as: "li" })`
  margin: ${rhOpsSpacingPx(2)} 0;
`;

const StyledBody1 = styled(Body1)`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

interface ConfirmModalProps {
  premises: PremiseRenewalsSearchType[];
  warnings: RenewalSegmentationWarnings[];
}

export const ConfirmModal = ({ premises, warnings }: ConfirmModalProps) => {
  return (
    <>
      <StyledBody1 as="p">
        Total number of premises: {premises.length}
      </StyledBody1>
      <Subtitle2 as="h4">Are you sure you want to proceed?</Subtitle2>
      {warnings.length > 0 && (
        <ul>
          {warnings.map((warning) => (
            <WarningItem as="li" key={warning}>
              {warning}
            </WarningItem>
          ))}
        </ul>
      )}
    </>
  );
};
