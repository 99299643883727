import { useFeatureFlagClient } from "@common/hooks/useFeatureFlagClient";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { BoLinkButton } from "@ops-design-system/components/BoLinkButton/BoLinkButton";
import { H1 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as Cog } from "@ops-design-system/icons/Cog.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { MIN_WIDTH } from "@pricing/constants/layout.constant";
import { offersBulkUpdatePath } from "@pricing/routes/routePaths";
import React from "react";
import styled from "styled-components";

const StyledPage = styled.div`
  margin-left: auto;
  margin-right: auto;
  min-width: ${MIN_WIDTH};
  width: 100%;
`;

export const Section = styled.section`
  margin: ${rhOpsSpacingPx(1)} 0;
`;

const StyledCard = styled(BoCard)`
  width: 50%;
`;

const StyledDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(4)};
  margin-top: ${rhOpsSpacingPx(2)};
`;

export const OffersManagementPage = () => {
  const { featureFlagClient } = useFeatureFlagClient();

  const { pricingOffersBulkUpdate } = featureFlagClient.getFlags([
    ["pricingOffersBulkUpdate", false],
  ]);

  return (
    <StyledPage>
      <BoFlexBox $flexDirection="column">
        <H1>Offers Management</H1>
        <StyledDivider />
        <StyledCard>
          <ButtonsGrid>
            {pricingOffersBulkUpdate.value ? (
              <BoLinkButton
                variant="secondary"
                icon={Cog}
                to={offersBulkUpdatePath()}
                fullWidth={false}
              >
                Bulk Update
              </BoLinkButton>
            ) : null}
          </ButtonsGrid>
        </StyledCard>
      </BoFlexBox>
    </StyledPage>
  );
};
