import { pricingApi } from "@pricing-data/api/pricingApi";
import { RenewalSegmentationQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { RenewalSegmentationSearchOptions } from "@pricing-utils/types/renewalsTypes";
import {
  keepPreviousData as reactQueryKeepPreviousData,
  useQuery,
} from "@tanstack/react-query";

interface QueryOptions {
  enabled?: boolean;
  keepPreviousData?: boolean;
}

interface UseRenewalSegmentationOffersProps {
  queryOptions: QueryOptions;
  searchOptions?: RenewalSegmentationSearchOptions;
}
export const useRenewalSegmentationOffers = (
  props: UseRenewalSegmentationOffersProps
) => {
  const {
    searchOptions = {},
    queryOptions: { keepPreviousData },
  } = props;

  return useQuery({
    placeholderData: keepPreviousData ? reactQueryKeepPreviousData : undefined,
    queryFn: () => pricingApi.renewals.segmentationOffers.list(searchOptions),
    queryKey: RenewalSegmentationQueryKeys.segmentationOffers(searchOptions),
  });
};
