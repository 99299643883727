import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { SelectField } from "@pricing/components/OffersSegmentation/shared/OffersSegmentation.styled";
import styled from "styled-components";

export const StyledBody2 = styled(Body2)`
  display: block;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
  margin-bottom: 16px;

  & > ${SelectField} {
    width: 250px;
  }
`;
