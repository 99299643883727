import { MobileRhHeader } from "@design-system/components/RhHeader/MobileRhHeader/MobileRhHeader";
import {
  StyledActionContainer,
  StyledAppBar,
  StyledCartContainer,
  StyledItem,
  StyledListContainer,
  StyledLogoContainer,
  StyledPhoneContainer,
} from "@design-system/components/RhHeader/RhHeader.styled";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export interface RhHeaderProps {
  cart?: JSX.Element | null;
  divideLastItem?: boolean;
  items: (JSX.Element | null)[];
  logo: JSX.Element;
  phone?: JSX.Element | null;
  premiseSelector?: JSX.Element | null;
}

export const RhHeader = ({
  cart,
  divideLastItem,
  items,
  logo,
  phone,
  premiseSelector,
}: RhHeaderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.MD));
  const isSmallMobile = useMediaQuery(
    theme.breakpoints.down(RhythmBreakpoints.SM)
  );

  const listItems = (
    <StyledListContainer data-testid="RhHeader__list">
      {items.map((item, index) => {
        if (!item) {
          return null;
        }

        if (item && !item.key) {
          throw new Error(
            "Each list element passed in the props must have a key"
          );
        }

        return (
          <React.Fragment key={item.key}>
            <StyledItem $divideLastItem={divideLastItem}>{item}</StyledItem>
          </React.Fragment>
        );
      })}
    </StyledListContainer>
  );

  return (
    <StyledAppBar position="fixed" elevation={0} role="navigation">
      <StyledActionContainer>
        <StyledLogoContainer data-testid="RhHeader__Logo">
          {logo}
        </StyledLogoContainer>

        {premiseSelector && !isSmallMobile ? (
          <div>{premiseSelector}</div>
        ) : null}
      </StyledActionContainer>

      {isMobile && items.length > 1 ? (
        <MobileRhHeader items={items} divideLastItem={divideLastItem} />
      ) : (
        // For some reason react complains about this, even if list items is a ul element. Adding the fragment worked but unsure why...
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{listItems}</>
      )}

      {cart && (
        <StyledCartContainer data-testid="RhHeader__cart">
          {cart}
        </StyledCartContainer>
      )}

      {phone && (
        <StyledPhoneContainer data-testid="RhHeader__phone">
          {phone}
        </StyledPhoneContainer>
      )}
    </StyledAppBar>
  );
};
