import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import styled from "styled-components";

export const RhAnchor = styled.a`
  color: ${CSSVariables.COLOR_PRIMARY_MAIN};
  text-decoration: none;

  &:hover {
    color: ${CSSVariables.COLOR_PRIMARY_MEDIUM};
    text-decoration: underline;
  }

  &:active {
    color: ${CSSVariables.COLOR_PRIMARY_DARK};
    text-decoration: underline;
  }
`;
