import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { fontFamily } from "@design-system/theme/typography";
import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 1000px;
    font-family: ${fontFamily};
    font-size: 16px;
    font-weight: ${FontWeight.Semibold};
    line-height: 14px;
    padding: ${rhSpacingPx(2)} ${rhSpacingPx(3)};
    text-transform: none;
    white-space: nowrap;

    &.Mui-disabled {
      border-color: ${CSSVariables.COLOR_DISABLED_BORDER};

      &.MuiButton-contained {
        background-color: ${CSSVariables.COLOR_DISABLED_BACKGROUND};
        color: ${CSSVariables.COLOR_DISABLED_TEXT};
        cursor: not-allowed;
      }

      &.MuiButton-text {
        color: ${CSSVariables.COLOR_DISABLED_TEXT};
      }
    }

    &.MuiButton-colorInherit {
      &.MuiButton-contained {
        background-color: ${CSSVariables.COLOR_GREY_50};
        color: ${CSSVariables.COLOR_GREY_500};
        cursor: not-allowed;
      }

      &.MuiButton-text {
        color: ${CSSVariables.COLOR_GREY_400};
      }
    }
  }

  &.MuiButtonBase-root {
    border-radius: 1000px;
  }

  &.MuiButton-contained,
  &.MuiButton-outlined {
    padding: ${rhSpacingPx(2)} ${rhSpacingPx(3)};
  }

  &.MuiButton-contained {
    background-color: ${CSSVariables.COLOR_GREY_50};
    color: ${CSSVariables.COLOR_GREY_500};

    &:hover {
      background-color: ${CSSVariables.COLOR_GREY_100};
    }

    &.Mui-disabled {
      background-color: ${CSSVariables.COLOR_DISABLED_BACKGROUND};
    }
  }

  &.MuiButton-containedPrimary {
    background-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    border-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    color: ${CSSVariables.COLOR_WHITE};

    &:hover {
      background-color: ${CSSVariables.COLOR_PRIMARY_LIGHT};
      border-color: ${CSSVariables.COLOR_PRIMARY_LIGHT};
    }

    &:active {
      background-color: ${CSSVariables.COLOR_PRIMARY_DARK};
      border-color: ${CSSVariables.COLOR_PRIMARY_DARK};
    }
  }

  &.MuiButton-outlined {
    background-color: ${CSSVariables.COLOR_WHITE};

    &:hover {
      background-color: ${CSSVariables.COLOR_PRIMARY_LIGHTEST};
    }

    &:active {
      background-color: ${CSSVariables.COLOR_PRIMARY_LIGHT};
    }
  }

  &.MuiButton-outlinedPrimary {
    border: 1px solid ${CSSVariables.COLOR_PRIMARY_MAIN};
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};

    &:hover {
      border-color: ${CSSVariables.COLOR_PRIMARY_LIGHTEST};
    }

    &:active {
      border-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    }
  }

  &.MuiButton-sizeLarge {
    font-size: 18px;
    line-height: 18px;
    padding: ${rhSpacingPx(2.5)} ${rhSpacingPx(4)};
  }

  &.MuiButton-sizeSmall {
    font-size: 14px;
    line-height: 10px;
    padding: ${rhSpacingPx(1.5)} ${rhSpacingPx(2)};

    &.MuiButton-text {
      padding: ${rhSpacingPx(1.5)} ${rhSpacingPx(2)};
    }
  }

  &.MuiButton-text {
    border: 0;
    color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    padding: ${rhSpacingPx(2)} ${rhSpacingPx(3)};

    &:hover {
      background-color: ${CSSVariables.COLOR_GREY_50};
    }

    &:active {
      background-color: ${CSSVariables.COLOR_GREY_100};
    }
  }
`;
