import { IdType } from "@common/types/apiTypes";
import { OrderIdType, OrderStatus } from "@common/types/orderTypes";

export const premiseRenewalTabs = ["#filterSearch", "#csvSearch"] as const;

export type PremiseRenewalTabType = (typeof premiseRenewalTabs)[number];

export interface RenewalOfferType {
  daysToExpiration: number;
  id: IdType;
  offerTitle: string;
}

export interface PremiseRenewalsSearchType {
  activeOrderId: IdType | null;
  contractEndDate: string | null;
  customerId: IdType;
  id: IdType;
  orderStatus: OrderStatus | null;
  planTitle: string | null;
  renewalCampaign: string | null;
  renewalOffers: RenewalOfferType[];
  rhythmKwhRate: number | null;
  solarEligible: boolean | null;
  utilityName: string;
}

export interface PremiseSearchFilters {
  duns: string;
  premiseIdsFile?: File;
}

interface RenewalOfferRequestType {
  daysToExpiration: number;
  offer: IdType;
}

export interface SetRenewalOffersRequestType {
  orders: OrderIdType[];
  renewalOffers: RenewalOfferRequestType[];
}

export interface SetRenewalCampaignRequestType {
  campaign: IdType;
  orders: OrderIdType[];
}

export interface OrderFilterParams {
  contractEndDateAfter?: string;
  contractEndDateBefore?: string;
  dunsNumber: string;
  limit?: number;
  planTitle?: string;
}

export enum RenewalSegmentationTypes {
  AUTOPAY = "Vanilla/Auto Pay",
  OTHER = "Vanilla/Other",
  POWER_TO_CHOOSE = "Power to Choose",
  POWER_TO_CHOOSE_TIME_OF_USE = "Power to Choose-Time of Use",
  SOLAR_BUYBACK = "Solar Buyback",
  TIME_OF_USE = "Time of Use",
}

export interface RenewalSegmentationOfferType {
  campaignId: IdType;
  campaignSlug: string;
  id: IdType;
  price2000Kwh: string;
  title: string;
}

export interface RenewalSegmentationCampaign {
  campaignSlug: string;
  id: IdType;
}

export interface RenewalSegmentationUtility {
  dunsNumber: string;
  id: IdType;
  name: string;
}

export interface RenewalSegmentationOffersType {
  id: IdType;
  myAccountCampaign: RenewalSegmentationCampaign | null;
  offerExpiration: RenewalSegmentationOfferType | null;
  offerSixtyDays: RenewalSegmentationOfferType | null;
  offerThirtyDays: RenewalSegmentationOfferType | null;
  type: RenewalSegmentationTypes;
  utility: RenewalSegmentationUtility;
}

export interface RenewalSegmentationOfferUpdateRequestType {
  id: IdType;
  myAccountCampaign?: IdType | null;
  offerExpiration?: IdType | null;
  offerSixtyDays?: IdType | null;
  offerThirtyDays?: IdType | null;
}

export interface RenewalSegmentationOfferCreateRequestType {
  myAccountCampaign: IdType | null;
  offerExpiration: IdType | null;
  offerSixtyDays: IdType | null;
  offerThirtyDays: IdType | null;
  type: RenewalSegmentationTypes;
  utility: IdType;
}

export enum RenewalSegmentationOrderOptions {
  "Id: High to Low" = "-id",
  "Id: Low to High" = "id",
  "Type A->Z" = "type",
  "Type Z->A" = "-type",
  "Utility A->Z" = "utility",
  "Utility Z-A" = "-utility",
}

export interface RenewalSegmentationSearchOptions {
  limit?: number;
  offset?: number;
  ordering?: RenewalSegmentationOrderOptions;
  type?: RenewalSegmentationTypes;
  utilityId?: IdType;
}
