import { useModalState } from "@common/hooks/useModalState";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { zodResolver } from "@hookform/resolvers/zod";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import {
  Body1,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as CirclePlus } from "@ops-design-system/icons/CirclePlus.svg";
import { useCreateRenewalOffersSegmentationMutation } from "@pricing-data/hooks/mutations/useCreateRenewalOffersSegmentation.mutation";
import { useCampaignsQuery } from "@pricing-data/hooks/queries/useCampaigns.query";
import { useOffers } from "@pricing-data/hooks/queries/useOffers";
import { useUtilities } from "@pricing-data/hooks/queries/useUtilities";
import {
  CampaignOrderOptions,
  CampaignTypeEnum,
} from "@pricing-utils/types/campaignTypes";
import { OfferOrderOptions } from "@pricing-utils/types/offerTypes";
import { RenewalSegmentationTypes } from "@pricing-utils/types/renewalsTypes";
import { OffersList } from "@pricing/components/OffersSegmentation/OffersList/OffersList";
import {
  Form,
  SubmitButton,
  TableContainer,
} from "@pricing/components/OffersSegmentation/OffersSegmentationCreate/OffersSegmentationCreate.styled";
import { SelectField } from "@pricing/components/OffersSegmentation/shared/OffersSegmentation.styled";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

interface OffersSegmentationCreateFormProps {
  close: () => void;
}

const offerSegmentationCreateSchema = z.object({
  filterCampaign: z.coerce.string().optional(),
  myAccountCampaign: z.coerce.string().nonempty(),
  offerExpiration: z.coerce.string().nonempty(),
  offerSixtyDays: z.coerce.string().nonempty(),
  offerThirtyDays: z.coerce.string().nonempty(),
  segmentationType: z.nativeEnum(RenewalSegmentationTypes),
  utility: z.coerce.string().nonempty(),
});

export type OfferSegmentationFormValues = z.infer<
  typeof offerSegmentationCreateSchema
>;

const OffersSegmentationCreateForm = (
  props: OffersSegmentationCreateFormProps
) => {
  const { close } = props;
  const flash = useRhFlash();

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid },
    register,
    setValue,
  } = useForm<OfferSegmentationFormValues>({
    resolver: zodResolver(offerSegmentationCreateSchema),
  });

  const filterCampaignId = watch("filterCampaign");
  const utilityId = watch("utility");

  const utilitiesQuery = useUtilities();

  const campaignsQuery = useCampaignsQuery({
    queryOptions: {
      enabled: Boolean(utilityId),
    },
    searchOptions: {
      active: true,
      campaignType: CampaignTypeEnum.Renewal,
      ordering: CampaignOrderOptions["Campaign Slug A->Z"],
      utilityId,
    },
  });

  const offersQuery = useOffers({
    queryOptions: {
      enabled: Boolean(filterCampaignId),
    },
    searchOptions: {
      active: true,
      campaignId: filterCampaignId,
      ordering: OfferOrderOptions["Title: A->Z"],
    },
  });

  const offersSegmentationCreateMutation =
    useCreateRenewalOffersSegmentationMutation();

  useEffect(() => {
    if (filterCampaignId) {
      setValue("offerThirtyDays", "");
      setValue("offerSixtyDays", "");
      setValue("offerExpiration", "");
    }
  }, [filterCampaignId, setValue]);

  useEffect(() => {
    if (utilityId) {
      setValue("filterCampaign", "");
      setValue("offerThirtyDays", "");
      setValue("offerSixtyDays", "");
      setValue("offerExpiration", "");
    }
  }, [utilityId, setValue]);

  if (utilitiesQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (utilitiesQuery.isError) {
    return (
      <BoCard>
        <Body1>Failed to fetch utilities</Body1>
      </BoCard>
    );
  }

  if (campaignsQuery.isError) {
    return (
      <BoCard>
        <Body1>Failed to fetch campaigns</Body1>
      </BoCard>
    );
  }

  if (offersQuery.isError) {
    return (
      <BoCard>
        <Body1>Failed to fetch offers</Body1>
      </BoCard>
    );
  }

  const onSubmit = (values: OfferSegmentationFormValues) => {
    offersSegmentationCreateMutation.mutate(
      {
        myAccountCampaign: values.myAccountCampaign,
        offerExpiration: values.offerExpiration,
        offerSixtyDays: values.offerSixtyDays,
        offerThirtyDays: values.offerThirtyDays,
        type: values.segmentationType,
        utility: values.utility,
      },
      {
        onError: () => {
          flash.error("Error creating Renewal Segmentation Offers");
        },
        onSuccess: () => {
          flash.success("Created Offer Segmentation");
          close();
        },
      }
    );
  };

  const campaignOptions = campaignsQuery?.data?.results.map((campaign) => {
    return (
      <option value={campaign.id} key={campaign.id}>
        {campaign.campaignSlug}
      </option>
    );
  });

  const offerOptions = offersQuery?.data?.results.map((offer) => {
    return (
      <option value={offer.id} key={offer.id}>
        {offer.title} - {offer.price2000Kwh}
      </option>
    );
  });

  const utilityOptions = utilitiesQuery.data.map((utility) => {
    return (
      <option value={utility.id} key={utility.id}>
        {utility.name}
      </option>
    );
  });

  const segmentationTypeOptions = Object.values(RenewalSegmentationTypes).map(
    (value) => {
      return (
        <option value={value} key={value}>
          {value}
        </option>
      );
    }
  );

  const submitDisabled =
    isSubmitting || !isValid || offersSegmentationCreateMutation.isPending;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <SelectField $gridAreaName="segmentationType">
        Segmentation Type
        <select {...register("segmentationType")}>
          <option value="">---</option>
          {segmentationTypeOptions}
        </select>
      </SelectField>

      <SelectField $gridAreaName="utility">
        Utility
        <select {...register("utility")}>
          <option value="">---</option>
          {utilityOptions}
        </select>
      </SelectField>

      <Subtitle2 $gridAreaName="offerTitle" $fontWeight="Bold">
        Offers View & Selection
      </Subtitle2>
      <SelectField $gridAreaName="campaign">
        Campaigns
        <select {...register("filterCampaign")}>
          <option value="">---</option>
          {campaignOptions}
        </select>
      </SelectField>

      <SelectField $gridAreaName="offer60">
        60 Day Window
        <select {...register("offerSixtyDays")}>
          <option value="">---</option>
          {offerOptions}
        </select>
      </SelectField>

      <SelectField $gridAreaName="offer30">
        30 Day Window
        <select {...register("offerThirtyDays")}>
          <option value="">---</option>
          {offerOptions}
        </select>
      </SelectField>

      <SelectField $gridAreaName="offerExpiration">
        Contract Expiration
        <select {...register("offerExpiration")}>
          <option value="">---</option>
          {offerOptions}
        </select>
      </SelectField>

      <Subtitle2 $gridAreaName="myAccountTitle" $fontWeight="Bold">
        My Account Campaigns
      </Subtitle2>
      <SelectField $gridAreaName="myAccount">
        Campaign for Portal Renewals
        <select {...register("myAccountCampaign")}>
          <option value="">---</option>
          {campaignOptions}
        </select>
      </SelectField>

      <TableContainer>
        {filterCampaignId ? (
          <>
            <BoCardLabel>Offers In Campaign</BoCardLabel>
            <OffersList campaignId={filterCampaignId} />
          </>
        ) : null}
      </TableContainer>

      <SubmitButton type="submit" disabled={submitDisabled}>
        Submit
      </SubmitButton>
    </Form>
  );
};

export const OffersSegmentationCreate = () => {
  const { open, close, isOpen } = useModalState(false);

  return (
    <>
      <BoButton
        variant="secondary"
        type="button"
        onClick={open}
        icon={CirclePlus}
      >
        Create Offer Segmentation
      </BoButton>
      {isOpen ? (
        <BoModal open onClose={close} size="large">
          <BoDialogHeader>Create Offer Segmentation</BoDialogHeader>
          <BoDialogBody>
            <OffersSegmentationCreateForm close={close} />
          </BoDialogBody>
        </BoModal>
      ) : null}
    </>
  );
};
