import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { Body1, H3 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { MIN_WIDTH } from "@pricing/constants/layout.constant";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ReturnLinkWrapper = styled(Body1).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const StyledPage = styled.div`
  margin-left: auto;
  margin-right: auto;
  min-width: ${MIN_WIDTH};
  width: 100%;
`;

export const Header = styled.div`
  display: grid;
  grid-template-areas:
    "link action"
    "title action";
  grid-template-columns: 1fr min-content;
`;

export const RenewalsPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionContainer = styled.div`
  grid-area: action;
`;

export const Title = styled(H3)`
  grid-area: title;
`;

export const StyledLink = styled(Link)`
  grid-area: link;
`;

export const StyledRenewalsPageDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(4)};
  margin-top: ${rhOpsSpacingPx(3)};
`;
