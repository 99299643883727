import { useOktaAuth } from "@okta/okta-react";
import { campaignsPath } from "@pricing/routes/routePaths";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const LoggedOutRoute = () => {
  const { authState } = useOktaAuth();
  const isAuthenticated = authState?.isAuthenticated ?? false;

  if (isAuthenticated) {
    return <Navigate to={campaignsPath()} />;
  }

  return <Outlet />;
};
