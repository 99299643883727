import { ediApi } from "@common/api/ediApi";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { RhApiError } from "@common/types/errorTypes";
import { isDefined } from "@common/utils/arrayUtils";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { pricingApi } from "@pricing-data/api/pricingApi";
import {
  OrderFilterParams,
  PremiseRenewalsSearchType,
} from "@pricing-utils/types/renewalsTypes";
import { UtilityType } from "@pricing-utils/types/utilityTypes";
import { OffersViewAndSelection } from "@pricing/components/PremiseSegmentation/OffersViewAndSelection/OffersViewAndSelection";
import {
  OrderFilterForm,
  OrderFilterFormValues,
} from "@pricing/components/PremiseSegmentation/OrderFilterForm/OrderFilterForm";
import { PremiseTable } from "@pricing/components/PremiseSegmentation/PremiseTable/PremiseTable";
import { PREMISE_ID_SEARCH_LIMIT } from "@pricing/constants/renewalSegmentation.constant";
import React, { useEffect, useState } from "react";

export const FilterSegmentation = () => {
  const [selectedUtility, setSelectedUtility] = useState<UtilityType | null>(
    null
  );

  const [formValues, setFormValues] = useState<OrderFilterFormValues | null>(
    null
  );

  const flash = useRhFlash();

  const handleFormSubmit = (values: OrderFilterFormValues) => {
    setFormValues(values);
    fetchPremises(values);
  };

  const [{ data: utilities }, utilityStateSetters] =
    useAjaxState<UtilityType[]>();

  const [{ data: premises }, premiseApiStateSetters] =
    useAjaxState<PremiseRenewalsSearchType[]>();

  useEffect(() => {
    utilityStateSetters.setPending();

    ediApi.utilities
      .index()
      .then(utilityStateSetters.setSuccess)
      .catch(utilityStateSetters.setFailure);
  }, []);

  // This is optionally typed to allow handleFormSubmit to send the value as setFormValues is async and this shouldn't be in a useEffect
  // And allows this to be a callback to other components
  const fetchPremises = (values: OrderFilterFormValues | null = formValues) => {
    if (!values) {
      flash.error("Could not fetch premises");
      return;
    }

    const matchedUtility = utilities?.find(
      (util) => util.id === values.utilityId
    );

    if (!matchedUtility) {
      // This is an unusual error because we're re-matching the id to the list of utilities used to generate the form values. If it happens, there's been some kind of data flow error, or the user has manually edited the form inputs.
      flash.error(
        "Something went wrong; we cannot find the utility you selected"
      );
      return;
    }
    setSelectedUtility(matchedUtility);

    premiseApiStateSetters.setInitialState();
    premiseApiStateSetters.setPending();

    const planTitles = values.planTitle
      .map((title) => title.value)
      .filter(isDefined);

    const searchParams: OrderFilterParams = {
      contractEndDateAfter: values.contractStartDate,
      contractEndDateBefore: values.contractEndDate,
      dunsNumber: matchedUtility.dunsNumber,
      limit: PREMISE_ID_SEARCH_LIMIT,
      planTitle: planTitles.join(", "),
    };

    pricingApi.renewals.premises
      .orderSearch(searchParams)
      .then((response) => {
        // Check if the response count is higher than the limit we set due to not having pagination
        if (response.count > PREMISE_ID_SEARCH_LIMIT) {
          flash.error(
            "The search returned more than 1000 results. Please refine your search and try again."
          );
          return;
        }

        if (response.count === 0) {
          flash.error("No premises found.");
          return;
        }
        premiseApiStateSetters.setSuccess(response.results);
      })
      .catch((error: RhApiError) => {
        const errorCodes = error.data.errors.map((err) => err.code);

        if (errorCodes.includes("renewals.premise.search.invalid_headers")) {
          const err = error.data.errors.filter(
            (e) => e.code === "renewals.premise.search.invalid_headers"
          )[0];

          flash.error(err.detail);
        } else {
          flash.error("Error occurred on search");
        }
        premiseApiStateSetters.setFailure(error);
      });
  };

  return (
    <>
      <OrderFilterForm
        handleFormSubmit={handleFormSubmit}
        utilities={utilities}
      />
      {/* This keeps typescript happy in the child components */}
      {premises && premises.length > 0 && selectedUtility && (
        <>
          <PremiseTable premises={premises} />
          <OffersViewAndSelection
            selectedUtility={selectedUtility}
            selectedPremises={premises}
            onSelectionSuccess={fetchPremises}
          />
        </>
      )}
    </>
  );
};
