import { pricingApi } from "@pricing-data/api/pricingApi";
import { PlanQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import {
  PlanPricingTypes,
  PlanSearchOptions,
} from "@pricing-utils/types/planTypes";
import { useQuery } from "@tanstack/react-query";

interface UsePlanProps {
  searchOptions: PlanSearchOptions;
}

export const usePlans = ({ searchOptions }: UsePlanProps) => {
  const {
    utilityId,
    archived,
    ordering,
    pricingType = PlanPricingTypes.FIXED_RATE,
  } = searchOptions;

  return useQuery({
    enabled: !!utilityId,
    queryFn: () =>
      pricingApi.plans.index({ archived, ordering, pricingType, utilityId }),
    queryKey: PlanQueryKeys.plansByUtility(utilityId, {
      archived,
      ordering,
      pricingType,
      utilityId,
    }),
    staleTime: 10000,
  });
};
