import { visuallyHidden } from "@ops-design-system/utils/styleHelpers";
import React, { ComponentPropsWithRef } from "react";
import styled from "styled-components";

const HiddenLabel = styled.label`
  ${visuallyHidden}
`;

/**
 * For use in the Header of a table only
 *
 *
 * Used to create an an IndeterminateCheckbox
 * We might want to look in the future to remove the useEffect and ref
 * but that would require removing type checking from the component
 * and we don't want to do that currently
 */
export const IndeterminateCheckbox = ({
  indeterminate,
  label,
  ...rest
}: {
  indeterminate?: boolean;
  label?: string;
} & ComponentPropsWithRef<"input">) => {
  const ref = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean" && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <>
      <input id="select-all-rows" type="checkbox" ref={ref} {...rest} />
      <HiddenLabel htmlFor="select-all-rows">Select All Rows</HiddenLabel>
    </>
  );
};
