import {
  BoButtonTab,
  BoButtonTabContainer,
} from "@ops-design-system/components/BoButtonTab/BoButtonTab";
import {
  PremiseRenewalTabType,
  premiseRenewalTabs,
} from "@pricing-utils/types/renewalsTypes";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const tabNameMapping: Record<PremiseRenewalTabType, string> = {
  "#csvSearch": "Upload Premise ID",
  "#filterSearch": "Premise Search",
};

export const PremiseRenewalTabs = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  const handleClick = (tab: PremiseRenewalTabType) => {
    navigate(tab);
  };

  return (
    <BoButtonTabContainer>
      {premiseRenewalTabs.map((tabName) => {
        const mappedName = tabNameMapping[tabName];

        return (
          <BoButtonTab
            key={tabName}
            data-active={hash === tabName}
            onClick={() => handleClick(tabName)}
          >
            {mappedName}
          </BoButtonTab>
        );
      })}
    </BoButtonTabContainer>
  );
};
