import { useModalState } from "@common/hooks/useModalState";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { zodResolver } from "@hookform/resolvers/zod";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import {
  Body1,
  Subtitle2,
} from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as Pencil } from "@ops-design-system/icons/Pencil.svg";
import { useUpdateRenewalOffersSegmentationMutation } from "@pricing-data/hooks/mutations/useUpdateRenewalOffersSegmentation.mutation";
import { useCampaignsQuery } from "@pricing-data/hooks/queries/useCampaigns.query";
import { useOffers } from "@pricing-data/hooks/queries/useOffers";
import {
  CampaignOrderOptions,
  CampaignTypeEnum,
} from "@pricing-utils/types/campaignTypes";
import { OfferOrderOptions } from "@pricing-utils/types/offerTypes";
import { RenewalSegmentationOffersType } from "@pricing-utils/types/renewalsTypes";
import { OffersList } from "@pricing/components/OffersSegmentation/OffersList/OffersList";
import {
  Form,
  SubmitButton,
  TableContainer,
} from "@pricing/components/OffersSegmentation/OffersSegmentationUpdate/OffersSegmentationUpdate.styled";
import { SelectField } from "@pricing/components/OffersSegmentation/shared/OffersSegmentation.styled";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

interface OffersSegmentationUpdateProps {
  renewalOffersSegmentation: RenewalSegmentationOffersType;
}

const offerSegmentationUpdateSchema = z.object({
  filterCampaign: z.coerce.string().optional(),
  myAccountCampaign: z.coerce.string().nonempty(),
  offerExpiration: z.coerce.string().nonempty(),
  offerSixtyDays: z.coerce.string().nonempty(),
  offerThirtyDays: z.coerce.string().nonempty(),
});

export type OfferSegmentationFormValues = z.infer<
  typeof offerSegmentationUpdateSchema
>;

interface OffersSegmentationUpdateFormProps {
  close: () => void;
  renewalOffersSegmentation: RenewalSegmentationOffersType;
}
const OffersSegmentationUpdateForm = (
  props: OffersSegmentationUpdateFormProps
) => {
  const { renewalOffersSegmentation, close } = props;

  const flash = useRhFlash();

  // For now we assume that each offer is using the same campaign so try to get it off any offer;
  const initialCampaignId =
    renewalOffersSegmentation.offerThirtyDays?.campaignId ??
    renewalOffersSegmentation.offerSixtyDays?.campaignId ??
    renewalOffersSegmentation.offerExpiration?.campaignId;

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid },
    register,
    setValue,
  } = useForm<OfferSegmentationFormValues>({
    defaultValues: {
      filterCampaign: initialCampaignId ?? "",

      myAccountCampaign: renewalOffersSegmentation.myAccountCampaign?.id,
      offerExpiration: renewalOffersSegmentation.offerExpiration?.id,
      offerSixtyDays: renewalOffersSegmentation.offerSixtyDays?.id,
      offerThirtyDays: renewalOffersSegmentation.offerThirtyDays?.id,
    },
    resolver: zodResolver(offerSegmentationUpdateSchema),
  });

  const filterCampaignId = watch("filterCampaign");

  const campaignsQuery = useCampaignsQuery({
    searchOptions: {
      active: true,
      campaignType: CampaignTypeEnum.Renewal,
      ordering: CampaignOrderOptions["Campaign Slug A->Z"],
      utilityId: renewalOffersSegmentation.utility.id,
    },
  });

  const offersQuery = useOffers({
    queryOptions: {
      enabled: Boolean(filterCampaignId),
    },
    searchOptions: {
      active: true,
      campaignId: filterCampaignId,
      ordering: OfferOrderOptions["Title: A->Z"],
    },
  });

  const offersSegmentationUpdateMutation =
    useUpdateRenewalOffersSegmentationMutation();

  useEffect(() => {
    if (filterCampaignId && filterCampaignId !== initialCampaignId) {
      setValue("offerThirtyDays", "");
      setValue("offerSixtyDays", "");
      setValue("offerExpiration", "");
    }
  }, [filterCampaignId, initialCampaignId, setValue]);

  const submitDisabled =
    isSubmitting || !isValid || offersSegmentationUpdateMutation.isPending;

  // We want to check for initialCampaignId first before doing loading checks, otherwise the content can't load properly
  // This shouldn't ever actually happen if updating one but is a failsafe
  if (
    initialCampaignId &&
    (campaignsQuery.isPending || offersQuery.isPending)
  ) {
    return <BoCircularProgress />;
  }

  if (campaignsQuery.isError) {
    return (
      <BoCard>
        <Body1>Failed to fetch campaigns</Body1>
      </BoCard>
    );
  }

  if (offersQuery.isError) {
    return (
      <BoCard>
        <Body1>Failed to fetch offers</Body1>
      </BoCard>
    );
  }

  const onSubmit = (values: OfferSegmentationFormValues) => {
    offersSegmentationUpdateMutation.mutate(
      {
        id: renewalOffersSegmentation.id,
        myAccountCampaign: values.myAccountCampaign ?? null,
        offerExpiration: values.offerExpiration ?? null,
        offerSixtyDays: values.offerSixtyDays ?? null,
        offerThirtyDays: values.offerThirtyDays ?? null,
      },
      {
        onError: () => {
          flash.error("Error updating Renewal Segmentation Offers");
        },
        onSuccess: () => {
          flash.success("Updated Renewal Segmentation Offers");
          close();
        },
      }
    );
  };

  const campaignOptions = campaignsQuery.data?.results.map((campaign) => {
    return (
      <option value={campaign.id} key={campaign.id}>
        {campaign.campaignSlug}
      </option>
    );
  });

  const offerOptions = offersQuery.data?.results.map((offer) => {
    return (
      <option value={offer.id} key={offer.id}>
        {offer.title} - {offer.price2000Kwh}
      </option>
    );
  });

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      aria-label="Update Renewal Segmentation Offers Form"
    >
      <Subtitle2 $gridAreaName="offerTitle" $fontWeight="Bold">
        Offers View & Selection
      </Subtitle2>
      <SelectField $gridAreaName="campaignSelect">
        Campaigns
        <select {...register("filterCampaign")}>{campaignOptions}</select>
      </SelectField>

      <SelectField $gridAreaName="offer60">
        60 Days Window
        <select {...register("offerSixtyDays")}>
          <option value="">---</option>
          {offerOptions}
        </select>
      </SelectField>

      <SelectField $gridAreaName="offer30">
        30 Days Window
        <select {...register("offerThirtyDays")}>
          <option value="">---</option>
          {offerOptions}
        </select>
      </SelectField>

      <SelectField $gridAreaName="offerExpiration">
        Contract Expiration
        <select {...register("offerExpiration")}>
          <option value="">---</option>
          {offerOptions}
        </select>
      </SelectField>

      {campaignsQuery.isPending ? (
        <BoCircularProgress />
      ) : (
        <>
          <Subtitle2 $gridAreaName="myAccountTitle" $fontWeight="Bold">
            My Account Campaigns
          </Subtitle2>
          <SelectField $gridAreaName="myAccount">
            Campaign for Portal Renewals
            <select {...register("myAccountCampaign")}>
              <option value="">---</option>
              {campaignOptions}
            </select>
          </SelectField>
        </>
      )}

      <TableContainer>
        {filterCampaignId ? (
          <>
            <BoCardLabel>Offers In Campaign</BoCardLabel>
            <OffersList campaignId={filterCampaignId} />
          </>
        ) : null}
      </TableContainer>

      <SubmitButton type="submit" disabled={submitDisabled}>
        Submit
      </SubmitButton>
    </Form>
  );
};

export const OffersSegmentationUpdate = (
  props: OffersSegmentationUpdateProps
) => {
  const { renewalOffersSegmentation } = props;
  const { open, close, isOpen } = useModalState(false);

  return (
    <>
      <BoButton
        variant="secondary"
        type="button"
        onClick={open}
        aria-label={`Edit Segmentation Offer ${renewalOffersSegmentation.id}`}
      >
        <Pencil />
      </BoButton>
      {isOpen ? (
        <BoModal open onClose={close} size="large">
          <BoDialogHeader>
            {`${renewalOffersSegmentation.utility.name} - ${renewalOffersSegmentation.type}`}
          </BoDialogHeader>
          <BoDialogBody>
            <OffersSegmentationUpdateForm
              renewalOffersSegmentation={renewalOffersSegmentation}
              close={close}
            />
          </BoDialogBody>
        </BoModal>
      ) : null}
    </>
  );
};
