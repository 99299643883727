import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: ${rhOpsSpacingPx(3)};

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: ${rhOpsSpacingPx(3)};
  }
`;

export const Container = styled.div`
  width: 100%;

  & > table {
    width: 100%;
  }
`;
