import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { Body1, H3 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { MIN_WIDTH } from "@pricing/constants/layout.constant";
import { offersManagementPath } from "@pricing/routes/routePaths";
import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface StyledAdminPageProps {
  pageName?: string;
  returnLinkText?: string;
  returnLinkTo?: string;
  subTitle?: React.ReactNode;
}

const ReturnLinkWrapper = styled(Body1).attrs({ as: "div" })`
  margin-bottom: ${rhOpsSpacingPx(1)};
`;

export const StyledPage = styled.div`
  margin-left: auto;
  margin-right: auto;
  min-width: ${MIN_WIDTH};
  width: 100%;
`;

const StyledDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(4)};
  margin-top: ${rhOpsSpacingPx(3)};
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledOfferPage = ({
  returnLinkTo,
  returnLinkText,
  pageName,
  subTitle,
  children,
}: PropsWithChildren<StyledAdminPageProps>) => {
  return (
    <StyledPage>
      <PageContainer>
        <ReturnLinkWrapper>
          <Link to={returnLinkTo ?? offersManagementPath()}>
            {returnLinkText ?? "< Offers"}
          </Link>
        </ReturnLinkWrapper>
        <H3 $fontWeight="SemiBold"> {pageName}</H3>
        {subTitle}
        <StyledDivider />
        {children}
      </PageContainer>
    </StyledPage>
  );
};
