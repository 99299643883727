import { pricingApi } from "@pricing-data/api/pricingApi";
import { CampaignQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { CampaignSearchFilters } from "@pricing-utils/types/campaignTypes";
import {
  keepPreviousData as reactQueryKeepPreviousData,
  useQuery,
} from "@tanstack/react-query";

interface UseCampaignsQueryProps {
  queryOptions?: {
    enabled?: boolean;
    keepPreviousData?: boolean;
  };
  searchOptions?: CampaignSearchFilters;
}
export const useCampaignsQuery = (props: UseCampaignsQueryProps = {}) => {
  const {
    searchOptions = {},
    queryOptions: { enabled = true, keepPreviousData = false } = {},
  } = props;

  return useQuery({
    enabled,
    placeholderData: keepPreviousData ? reactQueryKeepPreviousData : undefined,
    queryFn: () => pricingApi.campaigns.list(searchOptions),
    queryKey: CampaignQueryKeys.CAMPAIGNS(searchOptions),
  });
};
