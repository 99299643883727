import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { isDefined } from "@common/utils/arrayUtils";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButtonWithConfirmDialog } from "@ops-design-system/components/BoButtonWithConfirmDialog/BoButtonWithConfirmDialog";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { pricingApi } from "@pricing-data/api/pricingApi";
import { useOffers } from "@pricing-data/hooks/queries/useOffers";
import {
  CampaignStatusType,
  CampaignType,
} from "@pricing-utils/types/campaignTypes";
import { OfferType } from "@pricing-utils/types/offerTypes";
import { PremiseRenewalsSearchType } from "@pricing-utils/types/renewalsTypes";
import { ConfirmModal } from "@pricing/components/PremiseSegmentation/ConfirmModal/ConfirmModal";
import { RenewalSegmentationWarnings } from "@pricing/constants/renewalSegmentation.constant";
import React from "react";
import { Form, FormSpy } from "react-final-form";
import styled from "styled-components";

interface CampaignSelectorProps {
  campaigns: CampaignType[];
  setSelectedCampaign: (campaign: CampaignType) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

const CampaignSelector = ({
  setSelectedCampaign,
  campaigns,
}: CampaignSelectorProps) => {
  return (
    <Form
      onSubmit={() => {}}
      render={() => (
        <form noValidate>
          <StyledContainer>
            <BoSelectField
              name="campaign"
              label="Campaigns"
              options={campaigns
                .filter(
                  (campaign) => campaign.status !== CampaignStatusType.expired
                )
                .map((campaign) => ({
                  label: campaign.campaignSlug,
                  value: campaign.id,
                }))}
            />
            <FormSpy
              subscription={{ values: true }}
              onChange={({ values }) => {
                const campaignId = values.campaign;
                const campaign = campaigns.find((c) => c.id === campaignId);

                if (!campaign) {
                  return;
                }
                setSelectedCampaign(campaign);
              }}
            />
          </StyledContainer>
        </form>
      )}
    />
  );
};

interface OfferSelectorProps {
  disabled?: boolean;
  offers: OfferType[];
}

const OfferSelector = ({ offers, disabled = false }: OfferSelectorProps) => {
  const options =
    offers.map((offer) => ({
      label: offer.title,
      value: offer.id,
    })) ?? [];

  return (
    <StyledContainer>
      <BoGrid $variant={GridVariants.Grid3_1}>
        <BoGridColumn>
          <BoSelectField
            name="window60Days"
            label="60 Days Window"
            disabled={disabled}
            options={options}
          />
        </BoGridColumn>
        <BoGridColumn>
          <BoSelectField
            name="window30Days"
            label="30 Days Window"
            disabled={disabled}
            options={options}
          />
        </BoGridColumn>
        <BoGridColumn>
          <BoSelectField
            name="window0Days"
            label="Contract Expiration"
            disabled={disabled}
            options={options}
          />
        </BoGridColumn>
      </BoGrid>
    </StyledContainer>
  );
};

interface OfferSelectFormValues {
  window0Days: IdType;
  window30Days: IdType;
  window60Days: IdType;
}
interface OffersSelectionProps {
  campaigns: CampaignType[];
  onSelectionSuccess: () => void;
  selectedCampaign: CampaignType | null;
  selectedPremises: PremiseRenewalsSearchType[];
  setSelectedCampaign: (campaign: CampaignType) => void;
  warnings: RenewalSegmentationWarnings[];
}

export const OffersSelection = ({
  campaigns,
  selectedCampaign,
  setSelectedCampaign,
  selectedPremises,
  warnings,
  onSelectionSuccess,
}: OffersSelectionProps) => {
  const [, { setSuccess, setFailure, setPending }] = useAjaxState();
  const flash = useRhFlash();

  const offersQuery = useOffers({
    queryOptions: {
      enabled: Boolean(selectedCampaign),
    },
    searchOptions: {
      campaignId: selectedCampaign?.id,
      limit: 100,
    },
  });

  const offerSubmitHandler = (values: OfferSelectFormValues) => {
    if (!selectedPremises?.length) {
      flash.error("No premises are selected; please select at least one.");
      return;
    }
    setPending();

    const orders = selectedPremises
      .map((premise) => premise.activeOrderId)
      .filter(isDefined);

    const renewalOffers = [
      { daysToExpiration: 0, offer: values.window0Days },
      { daysToExpiration: 30, offer: values.window30Days },
      { daysToExpiration: 60, offer: values.window60Days },
    ];

    pricingApi.renewals
      .setRenewalOffers({ orders, renewalOffers })
      .then(() => {
        setSuccess();
        flash.success("Renewal offers successfully updated.");
        onSelectionSuccess();
      })
      .catch((error) => {
        setFailure();
        flash.error(
          "An error occurred while setting renewal offers. Please try again."
        );
      });
  };

  return (
    <>
      <CampaignSelector
        setSelectedCampaign={setSelectedCampaign}
        campaigns={campaigns}
      />
      <Form<OfferSelectFormValues>
        onSubmit={offerSubmitHandler}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <OfferSelector
              offers={offersQuery.data?.results ?? []}
              disabled={!selectedCampaign}
            />
            <StyledContainer>
              <BoButtonWithConfirmDialog
                header="Confirm Renewal Offers"
                message={
                  <ConfirmModal
                    warnings={warnings}
                    premises={selectedPremises}
                  />
                }
                handleConfirm={handleSubmit}
                buttonContent="Apply"
                variant="primary"
                buttonFullWidth={false}
                disabled={!selectedCampaign}
              />
            </StyledContainer>
          </form>
        )}
      />
    </>
  );
};
