import { useAjaxState } from "@common/hooks/useAjaxState";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { H1 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { pricingApi } from "@pricing-data/api/pricingApi";
import { OfferPromoListResponseType } from "@pricing-utils/types/pricingApiResponseTypes";
import { AddOfferPromo } from "@pricing/components/OfferPromos/AddOfferPromo/AddOfferPromo";
import { OfferPromosTable } from "@pricing/components/OfferPromos/OfferPromosTable/OfferPromosTable";
import { MIN_WIDTH } from "@pricing/constants/layout.constant";
import React, { PropsWithChildren, useEffect } from "react";
import styled from "styled-components";

export const Section = styled.section`
  margin: ${rhOpsSpacingPx(1)} 0;
`;

export const StyledHeaderBox = styled.div`
  padding-bottom: ${rhOpsSpacingPx(2.5)};
  padding-top: ${rhOpsSpacingPx(2.5)};
`;

export const StyledPage = styled.div`
  margin-left: auto;
  margin-right: auto;
  min-width: ${MIN_WIDTH};
  width: 100%;
`;

const HeaderFlexBox = styled(StyledHeaderBox)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const ButtonContainer = styled.div`
  margin-left: auto;
`;

interface BaseComponentProps {
  fetchOfferPromos: () => void;
}
const BaseComponent = ({
  children,
  fetchOfferPromos,
}: PropsWithChildren<BaseComponentProps>) => {
  return (
    <StyledPage>
      <HeaderFlexBox>
        <H1>Manage Offer Promos</H1>

        <ButtonContainer>
          <AddOfferPromo onSuccess={fetchOfferPromos} />
        </ButtonContainer>
      </HeaderFlexBox>

      {children}
    </StyledPage>
  );
};

export const OfferPromos = () => {
  const [{ data, requestMonitor }, { setSuccess, setPending, setFailure }] =
    useAjaxState<OfferPromoListResponseType>();

  const fetchOfferPromos = () => {
    setPending();
    pricingApi.offerPromos.list().then(setSuccess).catch(setFailure);
  };

  useEffect(() => {
    fetchOfferPromos();
  }, []);

  if (requestMonitor.isPending) {
    return (
      <BaseComponent fetchOfferPromos={fetchOfferPromos}>
        <BoCircularProgress />
      </BaseComponent>
    );
  }

  if (requestMonitor.didFail || !data || data.results.length === 0) {
    return (
      <BaseComponent fetchOfferPromos={fetchOfferPromos}>
        <BoCard>No Offer Promos Available</BoCard>
      </BaseComponent>
    );
  }

  return (
    <BaseComponent fetchOfferPromos={fetchOfferPromos}>
      <OfferPromosTable offerPromos={data.results} />
    </BaseComponent>
  );
};
