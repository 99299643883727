import { RhSecurity } from "@common/context/RhSecurity";
import { connectApi } from "@common/services/ajax";
import { AuthClient } from "@common/services/AuthClient.service";
import {
  getEnv,
  isLocal,
} from "@common/services/getEnvApplicationSettings.service";
import { brandTheme } from "@design-system/brandTheme/getBrandTheme";
import { GlobalBrandedThemeStyles } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { CssReset } from "@design-system/components/CssReset/CssReset";
import { RhFlashProvider } from "@design-system/components/RhFlashProvider/RhFlashProvider";
import { muiTheme } from "@design-system/theme/mui.theme";
import isPropValid from "@emotion/is-prop-valid";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { App } from "@pricing/App";
import { FeatureFlagClientPricingProvider } from "@pricing/components/FeatureFlagClientPricingProvider/FeatureFlagClientPricingProvider";
import { signInPath } from "@pricing/routes/routePaths";
import {
  AXIOS_BASE_URL,
  AXIOS_TIMEOUT_MS,
  OKTA_CONFIG,
  SENTRY_CONFIG,
} from "@pricing/settings/config";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { StyleSheetManager, ThemeProvider } from "styled-components";

connectApi({
  baseURL: AXIOS_BASE_URL,
  timeout: AXIOS_TIMEOUT_MS,
});
const authClient = AuthClient.getInstance(OKTA_CONFIG);

if (SENTRY_CONFIG.dsn) {
  Sentry.init({
    dsn: SENTRY_CONFIG.dsn,
    environment: getEnv(),
    integrations: [
      Sentry.rewriteFramesIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        createRoutesFromChildren,
        matchRoutes,
        useEffect,
        useLocation,
        useNavigationType,
      }),
    ],
    release: SENTRY_CONFIG.release,
    tracePropagationTargets: ["*.gotrhythm.com"],
    tracesSampleRate: SENTRY_CONFIG.tracesSampleRate,
  });
}

const queryClient = new QueryClient();

// This implements the default behavior from styled-components v5 until we can fix all existing props
function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === "string") {
    if (isLocal() && !isPropValid(propName)) {
      // eslint-disable-next-line no-console
      console.log({ propName, target });
    }
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

// React starts here
const container = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <MuiThemeProvider theme={muiTheme}>
        <CssReset />
        <BrowserRouter>
          <RhFlashProvider>
            <RhSecurity
              authClient={authClient}
              onAuthRequiredRedirectToPath={signInPath()}
            >
              <FeatureFlagClientPricingProvider>
                <QueryClientProvider client={queryClient}>
                  <ThemeProvider theme={brandTheme}>
                    <GlobalBrandedThemeStyles />
                    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                      <App />
                    </StyleSheetManager>
                  </ThemeProvider>
                </QueryClientProvider>
              </FeatureFlagClientPricingProvider>
            </RhSecurity>
          </RhFlashProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
