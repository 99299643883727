import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import styled from "styled-components";

export const StyledInput = styled(RhTextInput)`
  .MuiInput-input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
