import {
  StyledError,
  StyledInput,
  StyledInputLabel,
} from "@design-system/components/RhTextInput/RhTextInput.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import FormControl from "@mui/material/FormControl";
import { InputProps as IInputProps } from "@mui/material/Input";
import { InputBaseProps } from "@mui/material/InputBase";
import React, { PropsWithChildren } from "react";

export interface RhTextInputProps extends InputBaseProps {
  InputProps?: IInputProps;
  errorMessage?: string;
  name: string;
  placeholder: string;
}

export const RhTextInput = React.forwardRef(
  (props: PropsWithChildren<RhTextInputProps>, ref) => {
    const {
      className,
      name,
      error,
      errorMessage = "",
      InputProps,
      inputProps,
      children,
      disabled,
      ...restProps
    } = props;

    return (
      <FormControl fullWidth className={className}>
        <StyledInputLabel
          shrink
          disableAnimation
          htmlFor={name}
          error={error}
          disabled={disabled}
        >
          {children}
        </StyledInputLabel>
        <StyledInput
          id={name}
          name={name}
          error={error}
          disabled={disabled}
          inputProps={{ ...inputProps, tabIndex: 0 }}
          ref={ref}
          disableUnderline
          {...InputProps}
          {...restProps}
        />
        {error && (
          <StyledError>
            <RhTypography
              color="error"
              variant="caption"
              fontWeight={FontWeight.Regular}
            >
              {error ? errorMessage : ""}
            </RhTypography>
          </StyledError>
        )}
      </FormControl>
    );
  }
);
