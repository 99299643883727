import { EM_DASH } from "@common/constants/characters.constant";
import { formatCurrency } from "@common/utils/dataFormatters";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { PlanType } from "@pricing-utils/types/planTypes";
import { OfferBulkUpdateFormValues } from "@pricing/components/OfferBulkUpdateForm/formSchema";
import {
  calculateEnergyRateKWH,
  calculatePricePerMwh,
} from "@pricing/utils/calculations.util";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import styled from "styled-components";

const InfoContainer = styled.div`
  display: grid;
  gap: ${rhOpsSpacingPx(0.5)};
  grid-template-columns: 1fr;
`;

const PriceInfo = ({ plan }: { plan: PlanType }) => {
  const { control } = useFormContext<OfferBulkUpdateFormValues>();
  const price2000Kwh = useWatch({
    control,
    name: "price2000Kwh",
  });

  return (
    <InfoContainer>
      <Body2 $fontWeight="Bold">Price Info</Body2>
      <Body2 $fontWeight="SemiBold">Price @ 1000 KWH:</Body2>
      <Body2>
        {price2000Kwh
          ? calculatePricePerMwh({
              KWHUsage: 1000,
              decimalPlaces: 4,
              plan,
              price2000MWH: price2000Kwh,
            })
          : EM_DASH}
      </Body2>
      <Body2 $fontWeight="SemiBold">Price @ 500 KWH:</Body2>
      <Body2>
        {price2000Kwh
          ? calculatePricePerMwh({
              KWHUsage: 500,
              decimalPlaces: 4,
              plan,
              price2000MWH: price2000Kwh,
            })
          : EM_DASH}
      </Body2>
      <Body2 $fontWeight="SemiBold">Energy Rate:</Body2>
      <Body2>
        {price2000Kwh
          ? calculateEnergyRateKWH({
              decimalPlaces: 4,
              plan,
              price: price2000Kwh,
            })
          : EM_DASH}
      </Body2>
    </InfoContainer>
  );
};

const PlanInfo = ({ plan }: { plan: PlanType }) => {
  return (
    <InfoContainer>
      <Body2 $fontWeight="Bold">Plan Info</Body2>
      <Body2 $fontWeight="SemiBold">Utility: </Body2>
      <Body2>{plan.utility.name}</Body2>
      <Body2 $fontWeight="SemiBold">Plan Title: </Body2>
      <Body2>{plan.title}</Body2>
      <Body2 $fontWeight="SemiBold">TDSP Customer Charge: </Body2>
      <Body2>{plan.tdspCustomerChargeAmount}</Body2>
      <Body2 $fontWeight="SemiBold">TDSP Per Unit: </Body2>
      <Body2>{plan.tdspPerUnitMwhAmount}</Body2>
      <Body2 $fontWeight="SemiBold">Rhythm Base Charge: </Body2>
      <Body2>{formatCurrency(plan.baseChargeAmount)}</Body2>
      {plan.autopayPaperlessMonthlyDiscount && (
        <>
          <Body2 $fontWeight="SemiBold">
            Autopay/Paperless Monthly Credit:{" "}
          </Body2>
          <Body2>{formatCurrency(plan.autopayPaperlessMonthlyDiscount)}</Body2>
        </>
      )}
      <Body2 $fontWeight="SemiBold">$/MWH Termed COGS: </Body2>
      <Body2>{plan.termedCogsAmountMwh}</Body2>
      <Body2 $fontWeight="SemiBold">Term Volume: </Body2>
      <Body2>{plan.termVolumeMwh}</Body2>
      <Body2 $fontWeight="SemiBold">Annual Volume: </Body2>
      <Body2>{plan.annualVolumeMwh}</Body2>
    </InfoContainer>
  );
};

export const SidebarInfo = ({ plan }: { plan: PlanType }) => {
  return (
    <>
      <PlanInfo plan={plan} />
      <PriceInfo plan={plan} />
    </>
  );
};
