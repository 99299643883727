import { IdType } from "@common/types/apiTypes";
import { pricingApi } from "@pricing-data/api/pricingApi";
import { CampaignQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseCampaignQueryProps {
  campaignId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}
export const useCampaignQuery = (props: UseCampaignQueryProps) => {
  const { campaignId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => pricingApi.campaigns.retrieve(campaignId),
    queryKey: CampaignQueryKeys.RETRIEVE(campaignId),
  });
};
