import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { RhApiError } from "@common/types/errorTypes";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCalendarDatePicker } from "@design-system/components/RhCalendarDatePicker/RhCalendarDatePicker";
import { RhTextInput } from "@design-system/components/RhTextInput/RhTextInput";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoTableCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { campaignPromosApi } from "@pricing-data/api/campaignPromosApi";
import {
  CampaignPromoDataType,
  CampaignPromoType,
} from "@pricing-utils/types/campaignTypes";
import { toDecimalValue } from "@pricing/formatters/number.formatter";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";

interface EditCampaignPromoRowProps {
  campaignPromo: CampaignPromoType;
  onClose: (campaignPromoId: string) => void;
  onSuccessfulUpdate: (campaignPromoId: string) => void;
}

export const EditCampaignPromoRow = ({
  campaignPromo,
  onSuccessfulUpdate,
  onClose,
}: EditCampaignPromoRowProps) => {
  const flash = useRhFlash();

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [formValues, setFormValues] = useState<
    Record<keyof CampaignPromoDataType, string>
  >({
    code: campaignPromo.code,
    expirationDate: campaignPromo.expirationDate ?? "",
    oneTimeValue: campaignPromo.oneTimeValue,
    recurringValue: campaignPromo.recurringValue,
  });
  const [formErrors, setFormErrors] = useState<
    Record<keyof CampaignPromoDataType, string | null>
  >({
    code: null,
    expirationDate: null,
    oneTimeValue: null,
    recurringValue: null,
  });

  const handleSave = () => {
    const values = {
      ...formValues,
      expirationDate: formValues.expirationDate || undefined,
    };

    campaignPromosApi
      .update(campaignPromo.id, values)
      .then(() => {
        onSuccessfulUpdate(campaignPromo.id);
      })
      .catch((_error: RhApiError) => {
        flash.error(
          "We're having trouble updating this promo. Please try again later."
        );
      });
  };

  const handleInputChange = (
    formField: keyof CampaignPromoDataType,
    value: string
  ) => {
    const updatedFormValues = { ...formValues, [formField]: value };

    if (!updatedFormValues.oneTimeValue && !updatedFormValues.recurringValue) {
      setFormErrors({
        ...formErrors,
        oneTimeValue: "One or both values required.",
        recurringValue: "One or both values required.",
      });
      setSubmitDisabled(true);
    } else {
      setFormErrors({
        ...formErrors,
        oneTimeValue: null,
        recurringValue: null,
      });
      setSubmitDisabled(false);
    }

    setFormValues(updatedFormValues);
  };

  return (
    <BoTableRow key={campaignPromo.id}>
      <BoTableCell>{campaignPromo.id}</BoTableCell>

      <BoTableCell>{campaignPromo.code}</BoTableCell>

      <BoTableCell>
        <RhTextInput
          name="oneTimeValue"
          placeholder="One Time Value"
          value={formValues.oneTimeValue}
          error={Boolean(formErrors.oneTimeValue)}
          errorMessage={formErrors.oneTimeValue ?? ""}
          onChange={({ target: { value } }) => {
            handleInputChange("oneTimeValue", toDecimalValue(value, 2));
          }}
        >
          One Time Value
        </RhTextInput>
      </BoTableCell>

      <BoTableCell>
        <RhTextInput
          name="recurringValue"
          placeholder="Recurring Value"
          value={formValues.recurringValue}
          error={Boolean(formErrors.recurringValue)}
          errorMessage={formErrors.recurringValue ?? ""}
          onChange={({ target: { value } }) => {
            handleInputChange("recurringValue", toDecimalValue(value, 3));
          }}
        >
          Recurring Value
        </RhTextInput>
      </BoTableCell>

      <BoTableCell>
        <RhCalendarDatePicker
          label="Reward Bonus Deadline"
          format="MM/DD/YYYY"
          inputName="expirationDate"
          value={dayjs(formValues.expirationDate)}
          minDate={dayjs().add(1, "day")}
          onChange={(date: Dayjs) => {
            const formattedDate = date.format(ISO8601_DATE_FORMAT);

            handleInputChange("expirationDate", formattedDate ?? "");
          }}
          okLabel="OK"
          cancelLabel="Cancel"
        />
      </BoTableCell>

      <BoTableCell>
        <span>
          <RhButton
            data-tracking-click={{ event: "" }}
            fullWidth={false}
            onClick={() => onClose(campaignPromo.id)}
            color="primary"
            variant="outlined"
            size="small"
          >
            <RhTypography variant="body2">Cancel</RhTypography>
          </RhButton>
        </span>
        <span>
          <RhButton
            data-tracking-click={{ event: "" }}
            disabled={submitDisabled}
            fullWidth={false}
            onClick={handleSave}
            color="secondary"
            size="small"
          >
            <RhTypography variant="body2">Save</RhTypography>
          </RhButton>
        </span>
      </BoTableCell>
    </BoTableRow>
  );
};
