import { Validator } from "@common/forms/validators";

export const isAllCaps: Validator<string> = (value) => {
  if (!value) {
    return;
  }

  if (value !== value.toUpperCase()) {
    return "Must be all capital letters.";
  }
};

export const isValidOneOrBothRequired = (
  valueOne: string,
  valueTwo: string
) => {
  if (!valueOne && !valueTwo) {
    return "One or both values required.";
  }
};
