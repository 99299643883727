import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const Form = styled.form`
  display: grid;
  gap: ${rhOpsSpacingPx(1)};
  grid-template-columns: min-content 1fr;
  min-height: 550px;
  padding: ${rhOpsSpacingPx(2)};
`;

export const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
`;

export const SidebarContainer = styled.div`
  border-radius: ${rhOpsSpacingPx(0.5)};
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
  outline: 1px solid ${grey["100"]};
  padding: ${rhOpsSpacingPx(1)};
  width: 220px;
`;

export const StyledButton = styled(BoButton)`
  height: 40px;
`;

export const FormFieldSet = styled.fieldset`
  border: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0;
  padding: 0;
  & > legend {
    font-size: 14px;
    font-weight: bold;
    grid-column: 1 / -1;
    justify-self: start;
    margin-bottom: ${rhOpsSpacingPx(1)};
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  grid-column: 1 / -1;
  justify-content: flex-end;
  margin-top: 16px;
`;
