import { IdType } from "@common/types/apiTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { BoReactTable } from "@ops-design-system/components/BoReactTable/BoReactTable";
import { BoToggle } from "@ops-design-system/components/BoToggleField/BoToggle";
import { Body1, H2 } from "@ops-design-system/components/Typography/Typography";
import { useCampaignQuery } from "@pricing-data/hooks/queries/useCampaign.query";
import { useOffers } from "@pricing-data/hooks/queries/useOffers";
import { OfferType } from "@pricing-utils/types/offerTypes";
import {
  Container,
  HeaderContainer,
} from "@pricing/pages/CampaignOffersPage/CampaignOffersPage.styled";
import { offerPagePath } from "@pricing/routes/routePaths";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const columnHelper = createColumnHelper<OfferType>();

const columns = [
  columnHelper.accessor("id", {
    header: "Id",
  }),
  columnHelper.accessor("title", {
    header: "Title",
  }),
  columnHelper.accessor("descriptionEn", {
    header: "Description EN",
    meta: {
      wrapContent: true,
    },
  }),
  columnHelper.accessor("priority", {
    header: "Priority",
  }),
  columnHelper.accessor("price2000Kwh", {
    header: "Price",
  }),
  columnHelper.accessor("termMonths", {
    header: "Term Months",
  }),
  columnHelper.accessor("startDate", {
    header: "Start Date",
  }),
  columnHelper.accessor("endDate", {
    header: "End Date",
  }),
  columnHelper.accessor("earliestServiceStartDate", {
    header: "Service Start Date",
  }),
  columnHelper.display({
    cell: ({ row }) => {
      if (row.original.isActive) {
        return "Active";
      } else {
        return <RhTypography color="error">Expired</RhTypography>;
      }
    },
    header: "Status",
  }),
  columnHelper.accessor("promo.code", {
    header: "Promo Code",
  }),
  columnHelper.accessor("promo.value", {
    header: "Promo Value",
  }),
  columnHelper.accessor("offerLabels", {
    header: "Offer Labels",
  }),
  columnHelper.accessor("pricingType", {
    header: "Pricing Type",
  }),
  columnHelper.display({
    cell: ({ row }) => (
      <BoLink href={offerPagePath(row.original.id)}>View</BoLink>
    ),
    header: "",
    id: "editColumn",
  }),
];

export const CampaignOffersPage = () => {
  const { id } = useParams<{ id: IdType }>();

  const [hideExpired, setHideExpired] = useState<boolean>(true);

  const campaignQuery = useCampaignQuery({
    campaignId: id,
  });

  const offersQuery = useOffers({
    searchOptions: {
      active: hideExpired,
      campaignId: id,
      limit: 1000,
    },
  });

  if (campaignQuery.isPending || offersQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (campaignQuery.isError || offersQuery.isError) {
    return (
      <Container>
        <Body1>Error fetching campaign and offers</Body1>
      </Container>
    );
  }

  return (
    <Container>
      <HeaderContainer>
        <div>
          <RhTypography variant="h1">
            {`${campaignQuery.data.utility.name} - ${campaignQuery.data.campaignSlug}`}
          </RhTypography>
        </div>
        <div>
          <RhTypography variant="body1">Hide Expired Offers</RhTypography>
          <BoToggle
            inputProps={{
              checked: hideExpired,
              name: "Hide Expired Offers",
              onChange: () => {
                setHideExpired(!hideExpired);
              },
            }}
          />
        </div>
      </HeaderContainer>

      <H2>Offers for campaign</H2>

      <BoReactTable<OfferType>
        data={offersQuery.data.results}
        columns={columns}
        tableCaption="Offers Table"
        pagination={false}
      />
    </Container>
  );
};
