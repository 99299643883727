import { PriceMethodTypes } from "@pricing-utils/types/offerTypes";
import { z } from "zod";

const validDates = (firstDate?: string, secondDate?: string) => {
  if (firstDate && secondDate && firstDate > secondDate) {
    return false;
  }
  return true;
};

export function asOptionalField<T extends z.ZodTypeAny>(schema: T) {
  return schema.optional().or(z.literal("").transform(() => undefined));
}

const requirePrice = ({
  priceMethod,
  price2000Kwh,
  grossMarginPrice,
}: {
  grossMarginPrice?: number;
  price2000Kwh?: number;
  priceMethod?: PriceMethodTypes;
}) => {
  if (priceMethod === PriceMethodTypes.Fixed) {
    return !!price2000Kwh && price2000Kwh > 0;
  }
  if (priceMethod === PriceMethodTypes.Margin) {
    return !!grossMarginPrice && grossMarginPrice > 0;
  }
  return true;
};

const bulkUpdateFormSchema = z.object({
  earliestStartDate: asOptionalField(z.string()),
  endDate: asOptionalField(z.string()),
  grossMarginPrice: asOptionalField(z.coerce.number()),
  price2000Kwh: asOptionalField(z.coerce.number()),
  priceMethod: asOptionalField(z.nativeEnum(PriceMethodTypes)),
  solarBuybackRate: asOptionalField(z.coerce.number().positive()),
  startDate: asOptionalField(z.string()),
});

export const bulkUpdateFormValidation = bulkUpdateFormSchema
  .refine((data) => validDates(data.startDate, data.endDate), {
    message: "End Date must be after Start Date",
    path: ["endDate"],
  })
  .refine((data) => validDates(data.startDate, data.earliestStartDate), {
    message: "Earliest Service Start Date must equal or be after Start Date",
    path: ["earliestStartDate"],
  })
  .refine(
    (data) =>
      requirePrice({
        grossMarginPrice: data.grossMarginPrice,
        price2000Kwh: data.price2000Kwh,
        priceMethod: data.priceMethod,
      }),
    {
      message:
        "A positive value is needed for a price if price method is selected",
      path: ["priceMethod"],
    }
  );

export type OfferBulkUpdateFormValues = z.infer<typeof bulkUpdateFormSchema>;
