import { StyledButton } from "@design-system/components/RhButton/RhButton.styled";
import { ButtonProps } from "@mui/material";
import React, { PropsWithChildren } from "react";

export interface RhButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, "size" | "action" | "ref"> {
  className?: ButtonProps["className"];
  color?: ButtonProps["color"];
  "data-testid"?: string;
  "data-tracking-click": {
    [key: string]: string;
    event: string;
  };
  fullWidth?: ButtonProps["fullWidth"];
  size?: ButtonProps["size"];
  startIcon?: ButtonProps["startIcon"];
  type?: ButtonProps["type"];
  variant?: ButtonProps["variant"];
}
export const RhButton = (props: PropsWithChildren<RhButtonProps>) => {
  const {
    children,
    color = "primary",
    "data-tracking-click": dataTrackingClick,
    fullWidth = false,
    size = "medium",
    variant = "contained",
    ...restButtonProps
  } = props;

  return (
    <StyledButton
      color={color}
      data-tracking-click={JSON.stringify(dataTrackingClick)}
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      disableElevation
      {...restButtonProps}
    >
      {children}
    </StyledButton>
  );
};
