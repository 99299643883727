import { IdType } from "@common/types/apiTypes";

const RENEWALS_BASE_URL = "/api/renewals";

export const pricingUrls = {
  campaigns: {
    create: (): string => pricingUrls.campaigns.index(),
    index: (): string => "/api/pricing/campaigns/",
    retrieve: (id: IdType) => `/api/pricing/campaigns/${id}/`,
  },
  offerLabels: {
    list: (): string => "/api/pricing/offer-labels",
  },
  offerPromos: {
    create: (): string => pricingUrls.offerPromos.list(),
    list: (): string => "/api/pricing/offer-promos",
  },
  offers: {
    bulkUpdate: () => "/api/pricing/offers/bulk-update",
    create: () => pricingUrls.offers.list(),
    list: () => "/api/pricing/offers/",
    retrieve: (id: IdType) => `api/pricing/offers/${id}`,
    update: (id: IdType) => `/api/pricing/offers/${id}/`,
  },
  plans: {
    archive: (id: IdType) => `/api/pricing/plans/${id}/archive/`,
    download: (): string => "/api/pricing/plans/download/",
    index: (): string => "/api/pricing/plans",
    retrieve: (id: IdType) => `/api/pricing/plans/${id}`,
    upload: (): string => "/api/pricing/plans/upload/",
  },
  renewals: {
    premises: {
      csvSearch: () => `${RENEWALS_BASE_URL}/premises/csv-search/?limit=1000`,
      ordersSearch: () => `${RENEWALS_BASE_URL}/premises/filter-search/`,
    },
    segmentationOffers: {
      create: () => `${RENEWALS_BASE_URL}/renewal-segmentation-offers`,
      list: () => `${RENEWALS_BASE_URL}/renewal-segmentation-offers`,
      update: (id: IdType) =>
        `${RENEWALS_BASE_URL}/renewal-segmentation-offers/${id}/`,
    },
    setRenewalCampaign: () => `${RENEWALS_BASE_URL}/set-renewal-campaign`,
    setRenewalOffers: () => `${RENEWALS_BASE_URL}/set-renewal-offers`,
  },
};
