export const toSigFig = (value: number, dp = 7): number => {
  return parseFloat(value.toFixed(dp).toString());
};

export const toDecimalValue = (value: string, digits: number): string => {
  if (!value) {
    return "";
  }

  return (parseInt(value.replace(/\D/g, ""), 10) / 10.0 ** digits).toFixed(
    digits
  );
};
