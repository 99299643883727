import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { HTMLInputEvent } from "@design-system/types/domEventTypes";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import {
  BoDialogBody,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { pricingApi } from "@pricing-data/api/pricingApi";
import React, { useState } from "react";
import styled from "styled-components";

const StyledBody = styled(BoDialogBody)`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;

  & > button {
    margin: 0 8px;
  }

  & > form {
    grid-column: span 2;
    margin-bottom: 16px;
  }
`;

export const PlansUploadDialog = () => {
  const flash = useRhFlash();
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [csvFile, setCsvFile] = useState<File>();

  const handleSubmit = async () => {
    if (csvFile) {
      try {
        const response = await pricingApi.plans.upload(csvFile);

        if (response) {
          setDialogIsOpen(false);
          const { size } = response;

          flash.success(
            `Plans Menu of size: ${size} was successfully uploaded.`
          );
        }
      } catch (error) {
        const errorMsg = "Failed to upload file. Please check your data.";

        flash.error(errorMsg);
      }
    }
  };

  const handleChange = (event: HTMLInputEvent) => {
    if (event && event.target.files?.length) {
      const file = event?.target.files[0];
      const { name } = file;
      const pattern = /.csv$/;

      if (pattern.test(name) === false) {
        flash.error("File upload must be a CSV File");
        setCsvFile(undefined);
        return;
      }
      setCsvFile(file);
    }
  };

  return (
    <>
      <BoButton
        color="primary"
        size="small"
        onClick={() => setDialogIsOpen(true)}
      >
        Upload Plans
      </BoButton>

      <BoModal open={dialogIsOpen} onClose={() => setDialogIsOpen(false)}>
        <BoDialogHeader>Plans Upload</BoDialogHeader>
        <StyledBody>
          <form>
            <input
              onChange={handleChange}
              type="file"
              id="planUploadInput"
              aria-label="planUploadInput"
            />
          </form>
          <BoButton onClick={() => setDialogIsOpen(false)} variant="secondary">
            Cancel
          </BoButton>
          <BoButton onClick={() => handleSubmit()}>Save</BoButton>
        </StyledBody>
      </BoModal>
    </>
  );
};
