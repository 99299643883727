import { PriceMethodTypes } from "@pricing-utils/types/offerTypes";
import { PlanType } from "@pricing-utils/types/planTypes";
import {
  StyledDateInputAndLabel,
  StyledInputAndLabel,
  StyledSelectAndLabel,
} from "@pricing/components/OfferBulkUpdateForm/FormFields.styles";
import { OfferBulkUpdateFormValues } from "@pricing/components/OfferBulkUpdateForm/formSchema";
import {
  calculateGrossMargin,
  calculatePriceFromGrossMargin,
} from "@pricing/utils/calculations.util";
import React, { SyntheticEvent } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const PriceFields = ({ plan }: { plan: PlanType }) => {
  const {
    register,
    setValue,
    resetField,
    control,
    formState: { errors },
  } = useFormContext<OfferBulkUpdateFormValues>();
  const priceMethod = useWatch({
    control,
    name: "priceMethod",
  });

  return (
    <>
      <StyledSelectAndLabel>
        Price Method:
        <select
          {...register("priceMethod", {
            onChange: (event: SyntheticEvent<HTMLSelectElement>) => {
              if (!event.currentTarget.value) {
                resetField("price2000Kwh");
                resetField("grossMarginPrice");
              }
            },
          })}
        >
          <option aria-label="Null" value="">
            No Price Method Selected
          </option>
          <option value={PriceMethodTypes.Margin}>Margin Price</option>
          <option value={PriceMethodTypes.Fixed}>Fixed Price</option>
        </select>
        {errors.priceMethod && <strong>{errors.priceMethod.message}</strong>}
      </StyledSelectAndLabel>
      <StyledInputAndLabel>
        Price @ 2000 Kwh:
        <input
          type="number"
          step="any"
          aria-invalid={errors.price2000Kwh ? "true" : "false"}
          {...register("price2000Kwh", {
            disabled: priceMethod !== PriceMethodTypes.Fixed,
            min: 0,
            onChange: (event: SyntheticEvent<HTMLInputElement>) => {
              setValue(
                "grossMarginPrice",
                calculateGrossMargin({
                  plan,
                  price: Number(event.currentTarget.value),
                })
              );
            },
          })}
        />
        {errors.price2000Kwh && <strong>{errors.price2000Kwh.message}</strong>}
      </StyledInputAndLabel>
      <StyledInputAndLabel>
        Gross Margin Price:
        <input
          type="number"
          step="any"
          aria-invalid={errors.grossMarginPrice ? "true" : "false"}
          {...register("grossMarginPrice", {
            disabled: priceMethod !== PriceMethodTypes.Margin,
            min: 0,
            onChange: (event: SyntheticEvent<HTMLInputElement>) => {
              setValue(
                "price2000Kwh",
                calculatePriceFromGrossMargin({
                  grossMargin: Number(event.currentTarget.value),
                  plan,
                })
              );
            },
          })}
        />
        {errors.grossMarginPrice && (
          <strong>{errors.grossMarginPrice.message}</strong>
        )}
      </StyledInputAndLabel>
    </>
  );
};

export const SolarFields = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<OfferBulkUpdateFormValues>();

  return (
    <StyledInputAndLabel>
      Solar Buyback Rate:
      <input
        type="number"
        step="any"
        aria-invalid={errors.solarBuybackRate ? "true" : "false"}
        {...register("solarBuybackRate", {
          min: 0,
        })}
      />
      {errors.solarBuybackRate && (
        <strong>{errors.solarBuybackRate.message}</strong>
      )}
    </StyledInputAndLabel>
  );
};

export const DateFields = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<OfferBulkUpdateFormValues>();

  return (
    <>
      <StyledDateInputAndLabel>
        Start Date:
        <input
          type="date"
          aria-invalid={errors.startDate ? "true" : "false"}
          {...register("startDate")}
        />
        {errors.startDate && <strong>{errors.startDate.message}</strong>}
      </StyledDateInputAndLabel>

      <StyledDateInputAndLabel>
        End Date:
        <input
          type="date"
          aria-invalid={errors.endDate ? "true" : "false"}
          {...register("endDate")}
        />
        {errors.endDate && <strong>{errors.endDate.message}</strong>}
      </StyledDateInputAndLabel>

      <StyledDateInputAndLabel>
        Earliest Service Start Date:
        <input
          type="date"
          aria-invalid={errors.earliestStartDate ? "true" : "false"}
          {...register("earliestStartDate")}
        />
        {errors.earliestStartDate && (
          <strong>{errors.earliestStartDate.message}</strong>
        )}
      </StyledDateInputAndLabel>
    </>
  );
};
