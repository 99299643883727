import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacing, rhSpacingPx } from "@design-system/theme/spacing";
import { INPUT_ACTIVE_UNDERLINE_HEIGHT } from "@design-system/theme/style.constant";
import { fontFamily } from "@design-system/theme/typography";
import { Input, InputLabel } from "@mui/material";
import styled from "styled-components";

const focusBackgroundColor = "rgba(69, 0, 165, 0.04)";

export const StyledInputLabel = styled(InputLabel)`
  &.MuiInputLabel-root {
    color: ${CSSVariables.COLOR_GREY_600};
    font-family: ${fontFamily};
    font-size: 12px;
    font-weight: ${FontWeight.Bold};
    padding-bottom: 0.25rem;

    &.Mui-disabled {
      color: ${CSSVariables.COLOR_GREY_400};
    }

    &.Mui-error {
      color: ${CSSVariables.COLOR_ERROR_MAIN};
    }

    &.Mui-focused {
      color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    }
  }

  &.MuiInputLabel-shrink {
    transform: translateX(0) translateY(-2px);
  }
`;

export const StyledInput = styled(Input)`
  &.MuiInput-root {
    background-color: ${CSSVariables.COLOR_WHITE};
    border: 1px solid ${CSSVariables.COLOR_GREY_200};
    border-radius: 4px;
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, 0.04);
    caret-color: ${CSSVariables.COLOR_PRIMARY_MAIN};
    font-family: ${fontFamily};
    overflow: hidden;
    padding: 0.25rem 0.75rem 0.15rem;

    &.MuiIconButton-root:focus {
      background-color: ${focusBackgroundColor};
    }

    &.Mui-disabled {
      color: ${CSSVariables.COLOR_GREY_400};
      -webkit-text-fill-color: ${CSSVariables.COLOR_GREY_400};
    }

    &.Mui-error {
      border-color: ${CSSVariables.COLOR_ERROR_MAIN};

      & svg {
        color: ${CSSVariables.COLOR_GREY_900};
      }
    }

    &.Mui-focused {
      background-color: rgba(0, 0, 0, 0.01);
      border-color: ${CSSVariables.COLOR_PRIMARY_MAIN};

      & svg {
        color: ${CSSVariables.COLOR_PRIMARY_MAIN};
      }

      &.Mui-error {
        border-color: ${CSSVariables.COLOR_ERROR_MAIN};

        & svg {
          color: ${CSSVariables.COLOR_GREY_900};
        }
      }
    }
  }

  .MuiInput-input {
    font-size: 16px;
    height: 100%;
    margin-top: ${rhSpacingPx(1)};
    padding: 0 ${rhSpacingPx(1.5)} ${rhSpacingPx(1.5)} 0;

    &::placeholder {
      color: ${CSSVariables.COLOR_GREY_900};
    }

    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

export const StyledError = styled.div`
  bottom: ${(rhSpacing(2.25) + INPUT_ACTIVE_UNDERLINE_HEIGHT) * -1}px;
  left: 0;
  position: absolute;
`;
