import { red } from "@ops-design-system/styles/palette.colors";
import styled from "styled-components";

const StyledLabel = styled.label`
  display: inline-flex;
  flex-direction: column;
  font-weight: 600;
  & > strong {
    color: ${red["500"]};
  }
  & > * {
    width: 200px;
  }
`;

export const StyledInputAndLabel = styled(StyledLabel)``;

export const StyledDateInputAndLabel = styled(StyledLabel)``;

export const StyledSelectAndLabel = styled(StyledLabel)``;
