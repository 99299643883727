import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { UtilityType } from "@pricing-utils/types/utilityTypes";
import { CsvFormUploadButton } from "@pricing/components/PremiseSegmentation/CsvForm/CsvForm.styled";
import { PremiseIdCSVUpload } from "@pricing/components/PremiseSegmentation/PremiseIdCSVUpload/PremiseIdCSVUpload";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const SearchFormRow = styled.div`
  display: flex;
  flex-flow: row;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(1)};
`;

const StyledForm = styled.form`
  gap: ${rhOpsSpacingPx(4)};
  margin: ${rhOpsSpacingPx(3)} 0;
  width: 300px;
`;

export interface CsvFormValues {
  premiseIdsFile: File;
  utilityId: UtilityType["id"];
}

interface CsvFormProps {
  setPremiseFilters: (values: CsvFormValues) => void;
  utilities?: UtilityType[] | null;
}

export const CsvForm = ({ utilities, setPremiseFilters }: CsvFormProps) => {
  const options =
    utilities?.map((util) => ({
      label: util.name,
      value: util.id,
    })) ?? [];

  return (
    <Form<CsvFormValues>
      onSubmit={setPremiseFilters}
      initialValues={{
        premiseIdsFile: undefined,
        utilityId: "",
      }}
      render={({ handleSubmit, values, form }) => {
        const searchEnabled = !!values.utilityId && !!values.premiseIdsFile;

        return (
          <StyledForm onSubmit={handleSubmit} aria-label="CSV Upload Form">
            <SearchFormRow>
              <PremiseIdCSVUpload />
            </SearchFormRow>
            <SearchFormRow>
              <BoSelectField
                label="Utility"
                name="utilityId"
                options={options}
                placeholder="Utility"
              />
              <CsvFormUploadButton type="submit" disabled={!searchEnabled}>
                Upload and Search
              </CsvFormUploadButton>
            </SearchFormRow>
          </StyledForm>
        );
      }}
    />
  );
};
