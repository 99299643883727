import { ajax } from "@common/services/ajax";
import {
  CampaignPromoDataType,
  CampaignPromoType,
} from "@pricing-utils/types/campaignTypes";

const BASE_PATH = "/api/pricing/campaign_promos";

export const campaignPromosApi = {
  create: (
    campaignPromoData: CampaignPromoDataType
  ): Promise<CampaignPromoType> => {
    return ajax.post<CampaignPromoType, CampaignPromoDataType>(
      BASE_PATH,
      campaignPromoData
    );
  },
  list: (): Promise<CampaignPromoType[]> =>
    ajax.get<CampaignPromoType[]>(BASE_PATH),
  update: (
    id: string,
    campaignPromoData: CampaignPromoDataType
  ): Promise<CampaignPromoType> => {
    return ajax.put(`${BASE_PATH}/${id}/`, campaignPromoData);
  },
};
