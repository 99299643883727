import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { fontFamily } from "@design-system/theme/typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const SelectField = styled.label`
  color: ${grey["500"]};
  display: inline-flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: ${FontWeight.Bold};
  gap: ${rhOpsSpacingPx(0.5)};
  max-width: 250px;
  min-width: 200px;
  width: 100%;

  & > select {
    border: none;
    border-bottom: 1px solid ${grey["300"]};
    color: ${grey["900"]};
    cursor: pointer;
    font-family: ${fontFamily};
    font-size: 16px;
    padding: ${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(1)} ${rhOpsSpacingPx(1)} 0;
    text-overflow: ellipsis;

    &:focus-visible {
      border-radius: 4px;
      outline: ${darkPurple["500"]} solid 1px;
    }
  }
`;

export const StyledBoLabel = styled(BoLabel)`
  align-items: center;
  display: inline-flex;
  gap: ${rhOpsSpacingPx(1)};
  height: min-content;
  &:last-of-type {
    margin-left: auto;
  }
`;

export const FilterFormContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(3)};
  justify-content: flex-start;
  margin-bottom: 24px;
  width: 100%;

  & > :last-child {
    margin-left: auto;
  }
`;
