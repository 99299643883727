import { PlanType } from "@pricing-utils/types/planTypes";
import { AVERAGE_MONTHLY_USAGE } from "@pricing/constants/offer.constant";
import { toSigFig } from "@pricing/formatters/number.formatter";

interface CalculateUtilityChargesProps {
  KWHUsage?: number;
  plan: PlanType;
}

const calculateUtilityCharges = ({
  plan,
  KWHUsage = 2000,
}: CalculateUtilityChargesProps) => {
  const tdspKwhRate = parseFloat(plan.tdspPerUnitMwhAmount) / 1000;
  const tdspMonthlyCharge = parseFloat(plan.tdspCustomerChargeAmount);

  return tdspKwhRate + tdspMonthlyCharge / KWHUsage;
};

interface CalculatePricePerMwhProps {
  KWHUsage: number;
  decimalPlaces?: number;
  plan: PlanType;
  price2000MWH: number;
}

export const calculatePricePerMwh = ({
  price2000MWH,
  plan,
  KWHUsage,
  decimalPlaces = 7,
}: CalculatePricePerMwhProps) => {
  const utilityCharges = calculateUtilityCharges({ KWHUsage, plan });
  const rhythmCharges = calculateEnergyRateKWH({ plan, price: price2000MWH });

  let monthlyCharge = Number(plan.baseChargeAmount);

  if (plan.autopayPaperlessMonthlyDiscount) {
    monthlyCharge -= Number(plan.autopayPaperlessMonthlyDiscount);
  }

  if (plan.thermostatManagementMonthlyDiscount) {
    monthlyCharge -= Number(plan.thermostatManagementMonthlyDiscount);
  }
  monthlyCharge /= KWHUsage;

  return toSigFig(
    rhythmCharges + utilityCharges + monthlyCharge,
    decimalPlaces
  );
};

interface CalculateGrossMarginProps {
  plan: PlanType;
  price: number;
}

export const calculateGrossMargin = ({
  price,
  plan,
}: CalculateGrossMarginProps) => {
  const utilityCharges = calculateUtilityCharges({
    KWHUsage: AVERAGE_MONTHLY_USAGE,
    plan,
  });
  const termedCogsAmountMwh = parseFloat(plan.termedCogsAmountMwh);
  const baseChargeAmount =
    Number(plan.baseChargeAmount) / AVERAGE_MONTHLY_USAGE;
  const rhythmKwhRate = price - utilityCharges - baseChargeAmount;

  return toSigFig(1000 * rhythmKwhRate - termedCogsAmountMwh);
};

interface CalculateEnergyRateKWHProps {
  decimalPlaces?: number;
  plan: PlanType;
  price: number;
}

export const calculateEnergyRateKWH = ({
  price,
  plan,
  decimalPlaces = 7,
}: CalculateEnergyRateKWHProps) => {
  const utilityCharges = calculateUtilityCharges({
    KWHUsage: AVERAGE_MONTHLY_USAGE,
    plan,
  });

  let monthlyCharge = Number(plan.baseChargeAmount);

  if (plan.autopayPaperlessMonthlyDiscount) {
    monthlyCharge -= Number(plan.autopayPaperlessMonthlyDiscount);
  }

  if (plan.thermostatManagementMonthlyDiscount) {
    monthlyCharge -= Number(plan.thermostatManagementMonthlyDiscount);
  }

  return toSigFig(
    price - utilityCharges - monthlyCharge / AVERAGE_MONTHLY_USAGE,
    decimalPlaces
  );
};

interface CalculateEnergyRateMWHProps {
  plan: PlanType;
  price: number;
}

export const calculateEnergyRateMWH = ({
  price,
  plan,
}: CalculateEnergyRateMWHProps) => {
  return toSigFig(calculateEnergyRateKWH({ plan, price }) * 1000);
};

interface CalculatePriceFromGrossMarginProps {
  grossMargin: number;
  plan: PlanType;
}

export const calculatePriceFromGrossMargin = ({
  grossMargin,
  plan,
}: CalculatePriceFromGrossMarginProps) => {
  const termedCogsAmountMwh = parseFloat(plan.termedCogsAmountMwh);
  const utilityCharges = calculateUtilityCharges({
    KWHUsage: AVERAGE_MONTHLY_USAGE,
    plan,
  });
  const baseChargeAmount =
    Number(plan.baseChargeAmount) / AVERAGE_MONTHLY_USAGE;

  return toSigFig(
    grossMargin / 1000 +
      termedCogsAmountMwh / 1000 +
      utilityCharges +
      baseChargeAmount
  );
};
