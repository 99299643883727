import React from "react";

export const UnauthorizedPage = () => {
  return (
    <>
      <h1>Unauthorized User</h1>
      <img
        src="https://miro.medium.com/max/640/1*rQkXDddmscAYxvyKxlj_Dg.jpeg"
        alt="Unauthorized User"
      />
    </>
  );
};
