import { pricingApi } from "@pricing-data/api/pricingApi";
import { RenewalSegmentationQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateRenewalOffersSegmentationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: pricingApi.renewals.segmentationOffers.update,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: RenewalSegmentationQueryKeys.segmentationOffers(),
      });
    },
  });
};
