import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableCellContentWidth,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { OfferPromoType } from "@pricing-utils/types/offerTypes";
import React from "react";
import styled from "styled-components";

interface OfferPromosTableProps {
  offerPromos: OfferPromoType[];
}

const HEADERS = [
  "Id",
  "Code",
  "Initial Value",
  "Future Value",
  "Presentation Value",
  "Expiration Date",
];

const StyledTable = styled(BoTable)`
  width: 100%;
`;

interface OfferPromoTableRowProps {
  offerPromo: OfferPromoType;
}
const OfferPromoTableRow = ({ offerPromo }: OfferPromoTableRowProps) => {
  return (
    <BoTableRow>
      <BoTableCellContentWidth $align="center">
        {offerPromo.id}
      </BoTableCellContentWidth>
      <BoTableCell $align="center">{offerPromo.code}</BoTableCell>
      <BoTableCell $align="center">
        {formatCurrency(offerPromo.value)}
      </BoTableCell>
      <BoTableCell>
        {formatCurrency(Number(offerPromo.futureValue))}
      </BoTableCell>
      <BoTableCell>
        {formatCurrency(Number(offerPromo.presentationValue))}
      </BoTableCell>
      <BoTableCell $align="center">
        {offerPromo.expirationDate
          ? formatMonthDayYear(offerPromo.expirationDate)
          : ""}
      </BoTableCell>
    </BoTableRow>
  );
};

export const OfferPromosTable = ({ offerPromos }: OfferPromosTableProps) => {
  return (
    <StyledTable>
      <BoTableHead>
        <BoTableRow>
          {HEADERS.map((header) => (
            <BoTableHeadCell key={header}>{header}</BoTableHeadCell>
          ))}
        </BoTableRow>
      </BoTableHead>
      <BoTableBody>
        {offerPromos.map((offerPromo) => (
          <OfferPromoTableRow
            offerPromo={offerPromo}
            key={`Offer Promo - ${offerPromo.id}`}
          />
        ))}
      </BoTableBody>
    </StyledTable>
  );
};
