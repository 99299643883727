import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { RhCalendarDatePicker } from "@design-system/components/RhCalendarDatePicker/RhCalendarDatePicker";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import * as RFF from "react-final-form";
import styled from "styled-components";

interface RhDateFieldProps {
  label: string;
  minDate?: Dayjs;
  name: string;
}

const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const DateField = ({ name, label, minDate }: RhDateFieldProps) => {
  const {
    meta,
    input: { value },
  } = RFF.useField<string>(name, {
    type: "date",
  });
  const form = RFF.useForm();
  const hasSyncError = Boolean(meta.error);
  const hasAsyncError = Boolean(meta.submitError);
  const hasValidationError =
    hasSyncError || (!meta.dirtySinceLastSubmit && hasAsyncError);

  const hasErrors = !meta.active && meta.touched && hasValidationError;

  return (
    <Container>
      <RhCalendarDatePicker
        hasErrors={hasErrors}
        label={label}
        format="MM/DD/YYYY"
        inputName={name}
        minDate={minDate}
        onChange={(date: Dayjs) => {
          const formattedDate = date.format(ISO8601_DATE_FORMAT);

          form.change(name, formattedDate);
        }}
        placeholder={label}
        value={value ? dayjs(value) : undefined}
        okLabel="OK"
        cancelLabel="Cancel"
      />
    </Container>
  );
};
