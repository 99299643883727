import { OffersSegmentationCreate } from "@pricing/components/OffersSegmentation/OffersSegmentationCreate/OffersSegmentationCreate";
import { OffersSegmentationTable } from "@pricing/components/OffersSegmentation/OffersSegmentationTable/OffersSegmentationTable";
import { StyledRenewalsPage } from "@pricing/pages/Renewals/shared/StyledRenewalsPage";
import React from "react";

export const OffersSegmentationPage = () => {
  return (
    <StyledRenewalsPage
      pageName="Renewals - Segmentation"
      headerComponent={<OffersSegmentationCreate />}
    >
      <OffersSegmentationTable />
    </StyledRenewalsPage>
  );
};
