import { formatDollarsToCents } from "@common/utils/dataFormatters";
import {
  BoTable,
  BoTableBody,
  BoTableCellContentWidth,
  BoTableHead,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import {
  Body2,
  Caption,
} from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import type { OfferType } from "@pricing-utils/types/offerTypes";
import React from "react";
import styled from "styled-components";

const StyledBoTableCell = styled(BoTableCellContentWidth)<{
  $tabularNums?: boolean;
}>`
  padding: ${rhOpsSpacingPx(0.5)} 0;

  &:first-child {
    flex: 4;
    padding-left: 0;
  }
  &:last-child {
    flex: 1;
    padding-right: 0;
  }
  ${({ $tabularNums = false }) =>
    $tabularNums &&
    `
    font-variant-numeric: tabular-nums;
  `}
`;

const StyledBoTableRow = styled(BoTableRow)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledBoTableBody = styled(BoTableBody)`
  max-height: 200px;
  overflow-y: scroll;
`;

interface StyledBoTableCellHeadProp {
  $scope: string;
}

const StyledBoTableCellHead = styled(
  StyledBoTableCell
).attrs<StyledBoTableCellHeadProp>((props) => ({
  as: "th",
  scope: props.$scope,
}))``;

const StyledBoTableHead = styled(BoTableHead)``;

const StyledBoTable = styled(BoTable)`
  width: 100%;
  & * {
    border: none !important;
    text-align: left !important;
  }
`;

const StyledBoTableCaption = styled(Caption).attrs({ as: "caption" })`
  font-size: ${rhOpsSpacingPx(2)};
  margin-bottom: ${rhOpsSpacingPx(2)};
  text-align: left;
`;

const OfferRow = ({ offer }: { offer: OfferType }) => {
  return (
    <StyledBoTableRow>
      <StyledBoTableCellHead $scope="row">{offer.title}</StyledBoTableCellHead>
      <StyledBoTableCell $tabularNums>
        {formatDollarsToCents(offer.price2000Kwh)}
      </StyledBoTableCell>
    </StyledBoTableRow>
  );
};

export interface RenewalOffersInCampaignProps {
  offers: OfferType[];
}

const ScrollingContainer = styled.div`
  max-height: 170px;
  overflow-y: scroll;
`;

export const OffersInCampaign = ({ offers }: RenewalOffersInCampaignProps) => {
  return (
    <StyledBoTable>
      <StyledBoTableCaption>Offers in this campaign</StyledBoTableCaption>
      <tr role="presentation">
        {/* eslint-disable-next-line jsx-a11y/no-interactive-element-to-noninteractive-role */}
        <td role="presentation">
          <ScrollingContainer>
            <StyledBoTable role="presentation">
              <StyledBoTableHead>
                <StyledBoTableRow>
                  <StyledBoTableCellHead $scope="col">
                    <Body2 $fontWeight="Bold">Plan</Body2>
                  </StyledBoTableCellHead>
                  <StyledBoTableCellHead $scope="col">
                    <Body2 $fontWeight="Bold">Price</Body2>
                  </StyledBoTableCellHead>
                </StyledBoTableRow>
              </StyledBoTableHead>
              <StyledBoTableBody style={{ marginTop: "20px" }}>
                {offers.length ? (
                  offers.map((offer) => (
                    <OfferRow key={offer.id} offer={offer} />
                  ))
                ) : (
                  <StyledBoTableRow>
                    <StyledBoTableCell colSpan={2}>
                      <em>No offers available</em>
                    </StyledBoTableCell>
                  </StyledBoTableRow>
                )}
              </StyledBoTableBody>
            </StyledBoTable>
          </ScrollingContainer>
        </td>
      </tr>
    </StyledBoTable>
  );
};
