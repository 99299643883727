import { EnvTypes } from "@common/enums/envTypes.enum";
import { getEnvApplicationSettings } from "@common/services/getEnvApplicationSettings.service";
import { OktaAuthOptions } from "@okta/okta-auth-js";
import { EnvSettings, allEnvApplicationSettings } from "@pricing/settings/env";

const envApplicationSettings = getEnvApplicationSettings(
  allEnvApplicationSettings as Record<EnvTypes, EnvSettings>
);

export const AXIOS_BASE_URL = envApplicationSettings.axiosBaseUrl;
export const AXIOS_TIMEOUT_MS: number = envApplicationSettings.axiosTimeoutMs;

export const OKTA_CONFIG = Object.freeze<OktaAuthOptions>({
  clientId: envApplicationSettings.oktaClientId,
  issuer: envApplicationSettings.oktaIssuer,
  pkce: true,
  redirectUri: envApplicationSettings.oktaRedirectUri,
  scopes: ["openid", "profile", "email", "groups"],
});

export const SENTRY_CONFIG = Object.freeze({
  dsn: envApplicationSettings.sentryDSN,
  release: envApplicationSettings.sentryRelease,
  tracesSampleRate: envApplicationSettings.sentryTracesSampleRate,
});

export const ADMIN_URL = envApplicationSettings.adminUrl;
