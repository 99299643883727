import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableCellContentWidth,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { yesNo } from "@ops-design-system/utils/booleanHelpers";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { pricingApi } from "@pricing-data/api/pricingApi";
import { PlansResponseType } from "@pricing-utils/types/planTypes";
import { PlansUploadDialog } from "@pricing/components/PlansUploadDialog/PlansUploadDialog";
import dayjs from "dayjs";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";

const ID_FIELD_NAME = "id";
const PlanFieldNames = [
  ID_FIELD_NAME,
  "Title",
  "Description",
  "Zuora Rate Plan Id",
  "Load Profile",
  "Product",
  "Utility",
  "Rec Product",
  "Bundled?",
  "$/MWh Termed COGS",
  "Annual Volume (MWh)",
  "TDSP Customer Charge ($)",
  "TDSP Per Unit ($/MWh)",
  "Base Charge ($)",
  "Term Months",
  "Term Volume (MWh)",
  "ETF Amount ($)",
  "Green?",
  "Solar Eligible?",
  "Solar Generation Capped?",
  "Generation to Consumption Ratio (%)",
  "Status",
] as const;

const MaxWidthTableCell = styled(BoTableCell)`
  max-width: 250px;
  overflow-wrap: break-word;
`;

const Container = styled.div`
  display: grid;
  width: 100%;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(2)};
  justify-content: flex-start;
  margin-bottom: ${rhOpsSpacingPx(4)};
`;

export const PlansPage = () => {
  const flash = useRhFlash();

  const headCells = PlanFieldNames.map((key) => ({ name: key }));

  const [
    { data: plans, requestMonitor: listPlansRequestMonitor },
    {
      setSuccess: setListPlansSuccess,
      setPending: setListPlansPending,
      setFailure: setListPlansFailure,
    },
  ] = useAjaxState<PlansResponseType>([]);
  const [
    { requestMonitor: archivePlanRequestMonitor },
    {
      setSuccess: setArchivePlanSuccess,
      setPending: setArchivePlanPending,
      setFailure: setArchivePlanFailure,
    },
  ] = useAjaxState<boolean | undefined>();

  const fetchPlans = useCallback(() => {
    setListPlansPending();
    return pricingApi.plans
      .index()
      .then((planResponse: PlansResponseType) => {
        setListPlansSuccess(planResponse);
      })
      .catch((error: RhApiError) => {
        setListPlansFailure(error);
        flash.error("Error getting plans. Please try again.");
      });
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  const csvDownload = async () => {
    const blob = await pricingApi.plans.download();
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");

    link.href = url;
    const fileName = `allRhythmPlans${dayjs().format(ISO8601_DATE_FORMAT)}.csv`;

    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  if (
    archivePlanRequestMonitor.isPending ||
    listPlansRequestMonitor.isPending
  ) {
    return <BoCircularProgress />;
  }

  const archivePlan = (planId: IdType) => {
    setArchivePlanPending();
    pricingApi.plans
      .archive(planId)
      .catch((error: RhApiError) => {
        setArchivePlanFailure(error);
        flash.error("Error archiving plans. Please try again.");
      })
      .then(() => {
        setArchivePlanSuccess(true);
        return fetchPlans();
      });
  };

  return (
    <Container>
      <HeaderContainer>
        <RhTypography variant="h1">Plans</RhTypography>
        <BoButton
          onClick={() => csvDownload()}
          color="primary"
          size="small"
          fullWidth={false}
        >
          Download Plans
        </BoButton>

        <PlansUploadDialog />
      </HeaderContainer>
      {plans?.length ? (
        <BoTable>
          <BoTableHead>
            <BoTableRow>
              {headCells.map((cell) => (
                <BoTableHeadCell key={cell.name}>
                  <RhTypography variant="body2">{cell.name}</RhTypography>
                </BoTableHeadCell>
              ))}
            </BoTableRow>
          </BoTableHead>
          <BoTableBody>
            {plans.map((plan) => (
              <BoTableRow key={plan.id}>
                <BoTableCellContentWidth>{plan.id}</BoTableCellContentWidth>
                <MaxWidthTableCell>{plan.title}</MaxWidthTableCell>
                <MaxWidthTableCell>{plan.descriptionEn}</MaxWidthTableCell>
                <MaxWidthTableCell>{plan.zuoraRatePlanId}</MaxWidthTableCell>
                <MaxWidthTableCell>{plan.loadProfile}</MaxWidthTableCell>
                <MaxWidthTableCell>{plan.product}</MaxWidthTableCell>
                <MaxWidthTableCell>{plan.utility.name}</MaxWidthTableCell>
                <MaxWidthTableCell>{plan.recProduct}</MaxWidthTableCell>
                <MaxWidthTableCell>
                  {plan.bundled ? "Yes" : "No"}
                </MaxWidthTableCell>
                <MaxWidthTableCell>
                  {plan.termedCogsAmountMwh}
                </MaxWidthTableCell>
                <MaxWidthTableCell>{plan.annualVolumeMwh}</MaxWidthTableCell>
                <MaxWidthTableCell>
                  {plan.tdspCustomerChargeAmount}
                </MaxWidthTableCell>
                <MaxWidthTableCell>
                  {plan.tdspPerUnitMwhAmount}
                </MaxWidthTableCell>
                <MaxWidthTableCell>{plan.baseChargeAmount}</MaxWidthTableCell>
                <MaxWidthTableCell>{plan.termMonths}</MaxWidthTableCell>
                <MaxWidthTableCell>{plan.annualVolumeMwh}</MaxWidthTableCell>
                <MaxWidthTableCell>{plan.etfAmount}</MaxWidthTableCell>
                <BoTableCell>{yesNo(plan.isGreen)}</BoTableCell>
                <BoTableCell>{yesNo(plan.solarEligible)}</BoTableCell>
                <BoTableCell>{yesNo(plan.solarGenerationCapped)}</BoTableCell>
                <BoTableCell>
                  {plan.solarGenerationToConsumptionRatio}
                </BoTableCell>
                <BoTableCell>
                  {plan.archived ? (
                    <RhTypography color="error">Archived</RhTypography>
                  ) : (
                    <BoButton
                      onClick={() => {
                        archivePlan(plan.id);
                      }}
                      color="default"
                      size="small"
                      fullWidth={false}
                      variant="secondary"
                    >
                      Archive Plan
                    </BoButton>
                  )}
                </BoTableCell>
              </BoTableRow>
            ))}
          </BoTableBody>
        </BoTable>
      ) : (
        <div>There are no plans available to show.</div>
      )}
    </Container>
  );
};
