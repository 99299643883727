import { IdType } from "@common/types/apiTypes";
import { CampaignSearchFilters } from "@pricing-utils/types/campaignTypes";
import { OfferSearchOptions } from "@pricing-utils/types/offerTypes";
import { PlanSearchOptions } from "@pricing-utils/types/planTypes";
import { RenewalSegmentationSearchOptions } from "@pricing-utils/types/renewalsTypes";

export const CampaignQueryKeys = {
  CAMPAIGNS: (options: CampaignSearchFilters = {}) =>
    ["campaigns", "list", options] as const,
  RETRIEVE: (campaignId: IdType) => ["campaigns", campaignId],
};

export const CampaignPromoQueryKeys = {
  CAMPAIGN_PROMOS: ["campaignPromos", "list"] as const,
};

export const UtilityQueryKeys = {
  UTILITIES: ["utilities", "list"] as const,
};

export const PlanQueryKeys = {
  BASE: ["plans"] as const,
  planById: (planId: IdType) => [PlanQueryKeys.BASE, planId] as const,
  plansByUtility: (utilityId: IdType, searchOptions?: PlanSearchOptions) =>
    [PlanQueryKeys.BASE, "list", "utility", utilityId, searchOptions] as const,
};

export const OfferQueryKeys = {
  LIST: (options: OfferSearchOptions = {}) =>
    ["offers", "list", options] as const,
  RETRIEVE: (offerId: IdType) => ["offers", offerId],
};

export const RenewalSegmentationQueryKeys = {
  segmentationOffers: (options: RenewalSegmentationSearchOptions = {}) =>
    ["renewals", "renewalSegmentationOffers", "list", options] as const,
};
