import { IdType } from "@common/types/apiTypes";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { useOffers } from "@pricing-data/hooks/queries/useOffers";
import { OfferOrderOptions, OfferType } from "@pricing-utils/types/offerTypes";
import { StyledTable } from "@pricing/components/OffersSegmentation/OffersList/OffersList.styled";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";

interface OffersListProps {
  campaignId: IdType;
}

const columnHelper = createColumnHelper<OfferType>();

const columns = [
  columnHelper.accessor("title", {
    header: "Plan",
  }),
  columnHelper.accessor("price2000Kwh", {
    header: "Price",
  }),
];

export const OffersList = (props: OffersListProps) => {
  const { campaignId } = props;

  const offersQuery = useOffers({
    searchOptions: {
      active: true,
      campaignId,
      limit: 100,
      ordering: OfferOrderOptions["Title: A->Z"],
    },
  });

  if (offersQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (offersQuery.isError) {
    return (
      <BoCard>
        <Body2>Error fetching offers for campaign</Body2>
      </BoCard>
    );
  }

  return (
    <StyledTable
      data={offersQuery.data.results}
      columns={columns}
      tableCaption="Offers in Campaign Table"
    />
  );
};
