import { IdType } from "@common/types/apiTypes";

export const campaignsPath = (): string => {
  return "/campaigns";
};

export const campaignOffersPath = (campaignId: string): string => {
  return `/campaign/${campaignId}/offers`;
};

export const campaignPromosPath = (): string => {
  return "/campaign-promos";
};

export const plansPath = (): string => {
  return "/plans";
};

export const signInPath = (): string => {
  return "/sign-in";
};

export const unauthorizedPath = (): string => {
  return "/unauthorized";
};

export const loginCallbackPath = (): string => {
  return "/implicit/callback";
};

export const logoutPath = (): string => {
  return "/logout";
};

export const offerPromosPath = (): string => {
  return "/offer-promos";
};

export const renewalsManagementPath = (): string => {
  return "/renewals";
};

export const renewalsPremiseSegmentationPath = () => {
  return "/renewals/premise-segmentation";
};

export const renewalsOffersSegmentationPath = () => {
  return "/renewals/offers-segmentation";
};

export const offersManagementPath = () => {
  return "/offers";
};

export const offersBulkUpdatePath = () => {
  return "/offers/bulk-update";
};

export const offerPagePath = (offerId: IdType) => {
  return `/offer/${offerId}`;
};
