import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const SubmitButton = styled(BoButton)`
  grid-area: submit;
  height: 40px;
`;

export const Form = styled.form`
  align-items: center;
  display: grid;

  gap: ${rhOpsSpacingPx(5)} ${rhOpsSpacingPx(1)};
  grid-template-areas:
    "offerTitle . . offersTable"
    "campaignSelect . . offersTable"
    "offer60 offer30 offerExpiration offersTable"
    "myAccountTitle . . ."
    "myAccount . . ."
    "submit . . .";

  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const TableContainer = styled.div`
  align-self: flex-start;
  grid-area: offersTable;
`;
