import { IdType } from "@common/types/apiTypes";
import { UtilityType } from "@pricing-utils/types/utilityTypes";

export enum CampaignTypeEnum {
  Acquisition = "ACQUISITION",
  Renewal = "RENEWAL",
  Swap = "SWAP",
  Winback = "WINBACK",
}

export interface CampaignPromoType {
  code: string;
  expirationDate?: string;
  id: IdType;
  oneTimeValue: string;
  recurringValue: string;
}

export enum CampaignStatusType {
  active = "Active",
  expired = "Expired",
  needsAttention = "Needs Attention",
}
export interface CampaignType {
  activeOffersCount: number;
  campaignSlug: string;
  id: IdType;
  promo?: CampaignPromoType;
  status: CampaignStatusType;
  type: CampaignTypeEnum;
  utility: UtilityType;
}

export enum CampaignOrderOptions {
  "Campaign Slug A->Z" = "campaign_slug",
  "Campaign Slug Z->A" = "-campaign_slug",
  "Campaign Type A->Z" = "campaign_type",
  "Campaign Type Z->A" = "-campaign_type",
  "Id: High to Low" = "-id",
  "Id: Low to High" = "id",
  "Utility: A->Z" = "utility",
  "Utility: Z->A" = "-utility",
}

export interface CampaignSearchFilters {
  active?: boolean;
  campaignSlug?: string;
  campaignType?: CampaignTypeEnum;
  dunsNumber?: string;
  limit?: number;
  offset?: number;
  ordering?: CampaignOrderOptions;
  utilityId?: IdType;
}

export type CampaignPromoDataType = Omit<CampaignPromoType, "id">;
