import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { ButtonsGrid } from "@ops-design-system/components/BoGrid/BoGrid";
import { BoLinkButton } from "@ops-design-system/components/BoLinkButton/BoLinkButton";
import { H1 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as MapPinWithPlus } from "@ops-design-system/icons/MapPinWithPlus.svg";
import { ReactComponent as RotatingArrows } from "@ops-design-system/icons/RotatingArrows.svg";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { StyledPage } from "@pricing/components/OfferPromos/OfferPromos";
import {
  renewalsOffersSegmentationPath,
  renewalsPremiseSegmentationPath,
} from "@pricing/routes/routePaths";
import React from "react";
import styled from "styled-components";

const StyledCard = styled(BoCard)`
  width: 50%;
`;

const StyledDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(4)};
  margin-top: ${rhOpsSpacingPx(2)};
`;

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RenewalsManagementPage = () => {
  return (
    <StyledPage>
      <StyledPageContainer>
        <H1>Renewals</H1>
        <StyledDivider />
        <BoCardLabel>Segmentation</BoCardLabel>
        <StyledCard>
          <ButtonsGrid>
            <BoLinkButton
              variant="secondary"
              icon={MapPinWithPlus}
              to={renewalsPremiseSegmentationPath()}
              fullWidth={false}
            >
              Premise Segmentation
            </BoLinkButton>
            <BoLinkButton
              variant="secondary"
              icon={RotatingArrows}
              to={renewalsOffersSegmentationPath()}
              fullWidth={false}
            >
              Offers Segmentation
            </BoLinkButton>
          </ButtonsGrid>
        </StyledCard>
      </StyledPageContainer>
    </StyledPage>
  );
};
