import { LoginCallback } from "@okta/okta-react";
import { SignInPage } from "@pricing/pages/SignInPage/SignInPage";
import { UnauthorizedPage } from "@pricing/pages/UnauthorizedPage/UnauthorizedPage";
import { LoggedOutRoute } from "@pricing/routes/LoggedOutRoute";
import {
  loginCallbackPath,
  signInPath,
  unauthorizedPath,
} from "@pricing/routes/routePaths";
import { withSentryReactRouterV6Routing as SentryWithSentryReactRouterV6Routing } from "@sentry/react";
import React from "react";
import { Route, Routes } from "react-router-dom";

// See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-routes--component
const SentryRoutes = SentryWithSentryReactRouterV6Routing(Routes);

export const LoggedOutRoutes = () => {
  return (
    <SentryRoutes>
      <Route element={<LoggedOutRoute />}>
        <Route path={signInPath()} element={<SignInPage />} />
        <Route path={unauthorizedPath()} element={<UnauthorizedPage />} />
        <Route path={loginCallbackPath()} element={<LoginCallback />} />
      </Route>
    </SentryRoutes>
  );
};
