import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { useAjaxState } from "@common/hooks/useAjaxState";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoDateField } from "@ops-design-system/components/BoDate/BoDate";
import {
  BoMultiSelectField,
  BoSelectOptionType,
} from "@ops-design-system/components/BoMultiSelectField/BoMultiSelectField";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { grey, trueWhite } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { pricingApi } from "@pricing-data/api/pricingApi";
import { PlanType } from "@pricing-utils/types/planTypes";
import { UtilityType } from "@pricing-utils/types/utilityTypes";
import React, { FC, useEffect, useState } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

interface OrderFilterFormProps {
  handleFormSubmit: (values: OrderFilterFormValues) => void;
  utilities: UtilityType[] | null;
}

export interface OrderFilterFormValues {
  contractEndDate: string;
  contractStartDate: string;
  planTitle: BoSelectOptionType[];
  utilityId: string;
}

const StyledContainer = styled.div`
  background-color: ${grey["50"]};
  border-radius: ${rhOpsSpacingPx(1)};
  margin-top: ${rhOpsSpacingPx(3)};
  padding: ${rhOpsSpacingPx(2)};
`;

const StyledForm = styled.form`
  background-color: ${trueWhite};
  border-radius: ${rhOpsSpacingPx(1)};
  padding: ${rhOpsSpacingPx(2)};
`;

const StyledFlexBox = styled.div`
  align-items: end;
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

const orderFilterFormValidator =
  generateValidationErrorCollector<OrderFilterFormValues>({
    utilityId: [opsAndPricingIsRequired],
  });

export const OrderFilterForm: FC<
  React.PropsWithChildren<OrderFilterFormProps>
> = ({ handleFormSubmit, utilities }) => {
  const [{ data: plans }, planStateSetters] = useAjaxState<PlanType[]>();

  const [planOptions, setPlanOptions] = useState<BoSelectOptionType[]>([]);

  useEffect(() => {
    planStateSetters.setPending();

    pricingApi.plans
      .index()
      .then(planStateSetters.setSuccess)
      .catch(planStateSetters.setFailure);
  }, []);

  // map utils to options
  const utilitiesOptions =
    utilities?.map((util) => ({
      label: util.name,
      value: util.id,
    })) ?? [];

  const handleOnChange = (option: BoSelectOptionType | null) => {
    if (option) {
      const utilityPlans = plans?.filter(
        (plan) => plan.utility.id === option.value
      );

      const newPlanOptions =
        utilityPlans?.map((plan) => ({
          label: plan.title,
          value: plan.title,
        })) ?? [];

      setPlanOptions(newPlanOptions);
    }
  };

  return (
    <StyledContainer>
      <Form<OrderFilterFormValues>
        onSubmit={handleFormSubmit}
        validate={orderFilterFormValidator}
        initialValues={{
          contractEndDate: "",
          contractStartDate: "",
          planTitle: [],
          utilityId: "",
        }}
        keepDirtyOnReinitialize
        render={({ handleSubmit, values }) => {
          const planSelectDisabled = !values.utilityId;

          return (
            <StyledForm onSubmit={handleSubmit} aria-label="Order Filter Form">
              <StyledFlexBox>
                <BoSelectField
                  label="Utility"
                  name="utilityId"
                  options={utilitiesOptions}
                  placeholder="Utility"
                  requiredIndicator
                  onChange={handleOnChange}
                />
                <BoMultiSelectField
                  label="Plan Title"
                  name="planTitle"
                  options={planOptions}
                  disabled={planSelectDisabled}
                />

                <BoDateField
                  name="contractStartDate"
                  label="Contract Ends After"
                  placeholder=""
                />
                <BoDateField
                  name="contractEndDate"
                  label="Contract Ends Before"
                  placeholder=""
                />
              </StyledFlexBox>
              <BoButton type="submit">Search</BoButton>
            </StyledForm>
          );
        }}
      />
    </StyledContainer>
  );
};
