import { ReactComponent as RhythmLogoDarkPurple } from "@common/images/RhythmLogoDarkPurple.svg";
import { RhHeader } from "@design-system/components/RhHeader/RhHeader";
import { useOktaAuth } from "@okta/okta-react";
import {
  campaignPromosPath,
  campaignsPath,
  logoutPath,
  offerPromosPath,
  offersManagementPath,
  plansPath,
  renewalsManagementPath,
  signInPath,
} from "@pricing/routes/routePaths";
import React from "react";
import { Link, NavLink } from "react-router-dom";

export const PricingHeader = () => {
  const { authState } = useOktaAuth();

  const isAuthenticated = authState?.isAuthenticated || false;

  let items = [
    <NavLink key={renewalsManagementPath()} to={renewalsManagementPath()}>
      Renewals
    </NavLink>,
    <NavLink key={campaignsPath()} to={campaignsPath()}>
      Campaigns
    </NavLink>,
    <NavLink key={offersManagementPath()} to={offersManagementPath()}>
      Offers
    </NavLink>,
    <NavLink key={campaignPromosPath()} to={campaignPromosPath()}>
      Campaign Promos
    </NavLink>,
    <NavLink key={plansPath()} to={plansPath()}>
      Plans
    </NavLink>,
    <NavLink key={offerPromosPath()} to={offerPromosPath()}>
      Offer Promos
    </NavLink>,
    <NavLink key={logoutPath()} to={logoutPath()}>
      Log Out
    </NavLink>,
  ];

  if (!isAuthenticated) {
    items = [
      <NavLink key={signInPath()} to={signInPath()}>
        Log In
      </NavLink>,
    ];
  }

  const logo = (
    <Link to={isAuthenticated ? campaignsPath() : signInPath()}>
      <RhythmLogoDarkPurple />
    </Link>
  );

  return <RhHeader items={items} logo={logo} />;
};
