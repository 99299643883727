import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledGrid = styled.div`
  display: grid;
  gap: ${rhOpsSpacingPx(2)};

  grid-template-areas:
    "heading greeting"
    ".  hideExpired";
  margin-bottom: ${rhOpsSpacingPx(3)};
  width: 100%;
`;

export const Greeting = styled(Body1)`
  grid-area: greeting;
  place-self: center end;
`;

export const ButtonContainer = styled.div`
  width: 150px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  grid-area: heading;
`;
