import { campaignPromosApi } from "@pricing-data/api/campaignPromosApi";
import { CampaignPromoQueryKeys } from "@pricing-data/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useCampaignPromos = () => {
  return useQuery({
    queryFn: campaignPromosApi.list,
    queryKey: CampaignPromoQueryKeys.CAMPAIGN_PROMOS,
  });
};
