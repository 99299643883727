import { IdType } from "@common/types/apiTypes";
import { capitalizeFirstLetterOfWords } from "@common/utils/stringFormatters";
import { useUtilities } from "@pricing-data/hooks/queries/useUtilities";
import {
  CampaignOrderOptions,
  CampaignSearchFilters,
  CampaignTypeEnum,
} from "@pricing-utils/types/campaignTypes";
import { campaignSearchFiltersAtom } from "@pricing/components/CampaignSearchForm/CampaignSearchForm.atoms";
import {
  FilterFormContainer,
  SelectField,
  StyledBoLabel,
} from "@pricing/components/CampaignSearchForm/CampaignSearchForm.styled";
import { useAtom } from "jotai";
import React from "react";

export interface CampaignSearchFormValues {
  active: boolean;
  campaignType: CampaignTypeEnum;
  utilityId: IdType;
}

export const CampaignSearchForm = () => {
  const [campaignFilters, setCampaignFilters] = useAtom(
    campaignSearchFiltersAtom
  );

  const utilitiesQuery = useUtilities();

  // map utils to options
  const utilitiesOptions =
    utilitiesQuery.data?.map((util) => ({
      label: util.name,
      value: util.id,
    })) ?? [];

  const utilitiesDisabled = Boolean(utilitiesOptions.length === 0);

  const handleChangeValue = <K extends keyof CampaignSearchFilters>(
    key: K,
    value: CampaignSearchFilters[K]
  ) => {
    setCampaignFilters({
      ...campaignFilters,
      [key]: value,
    });
  };

  return (
    <FilterFormContainer>
      <SelectField>
        Campaign Type
        <select
          onChange={(event) =>
            handleChangeValue(
              "campaignType",
              event.currentTarget.value as CampaignTypeEnum
            )
          }
          value={campaignFilters.campaignType}
        >
          <option value="">---</option>
          {Object.values(CampaignTypeEnum).map((value) => {
            return (
              <option value={value} key={value}>
                {capitalizeFirstLetterOfWords(value)}
              </option>
            );
          })}
        </select>
      </SelectField>
      <SelectField>
        Utility
        <select
          onChange={(event) =>
            handleChangeValue("utilityId", event.currentTarget.value)
          }
          disabled={utilitiesDisabled}
          value={campaignFilters.utilityId}
        >
          <option value="">---</option>
          {utilitiesOptions.map((option) => {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </SelectField>
      {utilitiesQuery.isError ? <span>Error fetching utilities</span> : null}
      {utilitiesQuery.data?.length === 0 && utilitiesQuery.isSuccess ? (
        <span>No Utilities found</span>
      ) : null}

      <StyledBoLabel>
        Active?
        <input
          type="checkbox"
          defaultChecked
          onChange={(event) =>
            handleChangeValue("active", event.currentTarget.checked)
          }
        />
      </StyledBoLabel>

      <SelectField>
        Sort By:
        <select
          onChange={(event) =>
            handleChangeValue(
              "ordering",
              event.currentTarget.value as CampaignOrderOptions
            )
          }
          value={campaignFilters.ordering}
        >
          {Object.entries(CampaignOrderOptions).map((entry) => {
            const [key, value] = entry;

            return (
              <option value={value} key={key}>
                {key}
              </option>
            );
          })}
        </select>
      </SelectField>
    </FilterFormContainer>
  );
};
